import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Divider } from 'antd';
import ContactDetails from './ContactDetails';
import NoteForm from '../components/NoteForm';
import { CallbackAdd } from '../components/CallbackLater'
import { PushCRM } from '../components/PushCRM'
import { Select } from 'antd';
import * as actions from '../store/actions/contact';
import i18next from 'i18next';


const { Option } = Select;


function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

class DispositionElement extends React.Component {
  state = {
    initial_disposition: null,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.subscriber_id !== this.props.subscriber_id) {
      this.setState({
        initial_disposition: null,
      });
    }
  }

  componentDidMount() {
    // Should not have a default, as we want to agent to click
    let current_disposition = '';
    if (this.props.current_disposition) {
      current_disposition = this.props.current_disposition.toString();
    }
    this.setState({
      initial_disposition: current_disposition,
    });
  }

  onChange = value => {
    this.setState({
      initial_disposition: value,
    });
    if (isFunction(this.props.postFuncUptDisposition)) {
      this.props.postFuncUptDisposition(value);
    }
  };

  render() {
    let counter = 0;
    const keys = Object.keys(this.props.list_disposition);
    return (
      <div>
        <h3>{i18next.t("Update Disposition")}:</h3>
        <Select
          style={{ width: 200 }}
          placeholder={i18next.t("Update Disposition")}
          onChange={this.onChange}
          defaultValue={this.state.initial_disposition}
        >
        {
          keys.map(item => {
            counter = counter + 1;
            return (
              <Option value={item} key={counter}>
                {this.props.list_disposition[item]}
              </Option>
            );
          })
        }
        </Select>
      </div>
    );
  }
}

function uuid() {
  var chars = '0123456789abcdef'.split('');

  var uuid = [], rnd = Math.random, r;
  uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
  uuid[14] = '4'; // version 4

  for (var i = 0; i < 36; i++) {
    if (!uuid[i]) {
      r = 0 | rnd() * 16;

      uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r & 0xf];
    }
  }

  return uuid.join('');
}

class ContactInfo extends React.Component {

  updateDisposition = (subscriber_id, disposition) => {
    this.props.updateDisposition(subscriber_id, disposition);
  }
  addNote = (subscriber_id, note) => {
    this.props.addNote(subscriber_id, note);
  }

  render() {
    return (
      <div>
        <Row>
          <Col span={16}>
            <ContactDetails />
          </Col>
          <Col span={8}>
            <DispositionElement
              key={uuid()}
              list_disposition={this.props.contact_data.callcenter_config.lead_disposition}
              subscriber_id={this.props.contact_data.subscriber_id}
              current_disposition={this.props.contact_data.current_disposition}
              postFuncUptDisposition={(disposition) => this.updateDisposition(this.props.contact_data.subscriber_id, disposition)}
            />
            <Divider style={{ margin: 10 }} />
            <NoteForm
              key={uuid()}
              requestType="put"
              subscriber_id={this.props.contact_data.subscriber_id}
              postFuncAddNote={(note) => this.addNote(this.props.contact_data.subscriber_id, note)}
            />
            <Divider style={{ margin: 0 }} />
            <CallbackAdd
              key={uuid()}
              subscriber_id={this.props.contact_data.subscriber_id}
            />
            <Divider style={{ margin: 0 }} />
            <PushCRM
              subscriber_id={this.props.contact_data.subscriber_id}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    // token: state.auth.token,
    contact_data: state.contact.contact_data
  }
}


const mapDispatchToProps = dispatch => {
  return {
    updateDisposition: (subscriber_id, disposition) => dispatch(actions.updateDisposition(subscriber_id, disposition)),
    addNote: (subscriber_id, note) => dispatch(actions.addNote(subscriber_id, note)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);

