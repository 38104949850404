import React from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import i18next from 'i18next';


class WrapupCountDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wrap_up_visible: false,
      left_seconds: (this.props.agent_wrap_up_time - this.props.secs_since_last_call)
    }
  }

  componentDidMount() {
    this.setState({
      left_seconds: this.props.agent_wrap_up_time - this.props.secs_since_last_call
    });
    this.timerID = setInterval(() => this.tickCountDown(), 1000);
    this.timerIDReload = setInterval(() => this.tickReload(), 3000);
  }

  tickReload() {
    // Re-sync with the API value secs_since_last_call
    this.setState({
      left_seconds: this.props.agent_wrap_up_time - this.props.secs_since_last_call
    });
  }

  tickCountDown() {
    const left_seconds = this.state.left_seconds - 1

    if (left_seconds > 0) {
      this.setState({
        left_seconds: left_seconds,
        wrap_up_visible: true,
      });
    } else {
      if (this.state.wrap_up_visible) {
        message.success(i18next.t("Wrap Up time completed, get ready to receive more calls!"), 2);
      }
      this.setState({
        left_seconds: left_seconds,
        wrap_up_visible: false,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
    clearInterval(this.timerIDReload);
  }

  render() {
    return (
      <>
        {
          (this.state.wrap_up_visible && this.props.secs_since_last_call !== 0)
            ?
            <h4 className="wrapup-countdown">
              {i18next.t("Wrap Up")}: {this.state.left_seconds}/{this.props.agent_wrap_up_time}
            </h4>
            :
            <br />
        }
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    agent_wrap_up_time: state.agent.agent_wrap_up_time,
    secs_since_last_call: state.contact.secs_since_last_call,
  }
}

export default connect(mapStateToProps)(WrapupCountDown);