import React from 'react';
import { connect } from 'react-redux';
import { Tabs, Empty, Button } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import ContactInfo from '../components/ContactInfo';
import ContactScript from '../components/ContactScript'
import Notes from '../components/Notes';
import ContactTimeline from '../components/ContactTimeline';
import Iframe from 'react-iframe'
import * as actions from '../store/actions/contact';
import Interweave from 'interweave';
import i18next from 'i18next';

const { TabPane } = Tabs;


class ContactView extends React.Component {

  tickContactLoad = () => {
    this.props.fetchContact();
  };

  componentDidMount() {
    this.props.fetchContact();
    let freq = 500
    if (window._env_.DEV_TEST_MODE) {
      freq = 2000
    }
    this.timerID = setInterval(this.tickContactLoad, freq);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    const custom_logged_message = window._env_.CUSTOM_LOGGED_MESSAGE || '';
    let crm_external_url = null;
    let internal_notes = null;
    let timeline_data = null;
    if (this.props.contact_data) {
      crm_external_url = this.props.contact_data['callcenter_config']['crm_external_url'];
      internal_notes = this.props.contact_data['contact_fields']['internal_notes'];
      timeline_data = this.props.contact_data['timeline'];
    }
    return (
      <div>
        {
          this.props.contact_data ?
            <div>
              <ContactInfo />
              <Tabs style={{ marginTop: 10 }}
                type="card"
              >
                <TabPane tab={i18next.t("Script")} key="1">
                  <ContactScript contact_data={this.props.contact_data} />
                </TabPane>
                <TabPane tab={i18next.t("History")} key="2">
                  <ContactTimeline data={timeline_data} />
                </TabPane>
                <TabPane tab={i18next.t("Notes")} key="3">
                  <Notes data={internal_notes} />
                </TabPane>
                <TabPane tab={i18next.t("External CRM")} key="4">

                  <Button type="primary" size="small" shape="round" icon={<LinkOutlined />} target="_blank" href={crm_external_url}>{i18next.t("Open CRM in a new window")}</Button>

                  <Iframe url={crm_external_url}
                    width="100%"
                    height="600px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative" />
                </TabPane>
              </Tabs>
            </div>
            :
            <center>
              <Empty description={i18next.t("No call detected!")} />
              {
                custom_logged_message ?
                  <Interweave content={custom_logged_message} />
                  :
                  <center></center>
              }
            </center>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    // token: state.auth.token,
    contact_data: state.contact.contact_data
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchContact: () => dispatch(actions.fetchContact())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactView);
