import React from 'react';
import { Timeline } from 'antd';
import { MessageOutlined } from '@ant-design/icons';

class Notes extends React.Component {
  render() {
    // let dictvalue = { '2019-07-25 18:09:33': "2nd note...", '2019-07-25 18:35:24': "2nd note...", '2019-07-25 18:09:14': "this is an extra note to attach to the subscriber and contact...", '2019-07-25 18:37:14': "2nd note..." }
    let unordered_dict = this.props.data;
    const listData = [];
    const ordered_dict = {};

    if (unordered_dict) {
      Object.keys(unordered_dict).sort().reverse().forEach(function (key) {
        ordered_dict[key] = unordered_dict[key];
      });

      for (var key in ordered_dict) {
        listData.push({ 'date': key, 'description': ordered_dict[key] });
      }
    }
    let counter = 0;
    return (
      <div>
        <Timeline mode="alternate">
          {
            listData.map(item => {
              counter = counter + 1;
              // let color = item == 5 ? 'geekblue' : 'green';
              let dot = '';
              let color = 'blue';
              dot = <MessageOutlined style={{ fontSize: '16px' }} />
              return (
                <Timeline.Item
                  key={counter}
                  color={color}
                  dot={dot}>
                  <b>{item.date}</b><br />
                  <span className="note-item">{item.description}</span>
                </Timeline.Item>
              );
            })}
        </Timeline>
      </div>
    );
  }
}

export default Notes;
