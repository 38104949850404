import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell } from 'recharts';
import { Spin, Button } from 'antd';
import { DatePicker, Space } from 'antd';
import { Statistic, Card, Row, Col } from 'antd';
import { PhoneOutlined, CustomerServiceOutlined, IssuesCloseOutlined } from '@ant-design/icons';
import { red, blue, green, gold } from '@ant-design/colors';
import { SearchOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import moment from 'moment';
import { prepAxiosHeaders } from '../store/utility';
import { Translation, Trans } from 'react-i18next';
import i18next from 'i18next';

const { Text } = Typography;

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#777">
        <Trans i18nKey="ValueCalls" value={value}>
          {{ value }} calls
        </Trans>
      </text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        ({i18next.t("Rate")} {`${(percent * 100).toFixed(2)}%`})
      </text>
    </g>
  );
};

const COLORS = [
  green[3], red[3], '#d65db1', '#ff6f91', '#ff9671', '#ffc75f',
  '#efe7bc', '#FFBB28', '#FF8042', '#4c5270', '#f652a0', '#36eee0',
  '#74bdcb', '#FFBB28', '#ffa384', '#b39cd0', '#00c9a7', '#c4fcef',
  '#c34a36', '#ff8066', '#d5cabd', '#9b89b3', '#008dcd', '#00d2fc',
];

class DonutChart extends PureComponent {

  state = {
    activeIndex: 0,
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    return (
      <PieChart width={500} height={300}>
        <Pie
          activeIndex={this.state.activeIndex}
          activeShape={renderActiveShape}
          data={this.props.data}
          cx={250}
          cy={150}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          onMouseEnter={this.onPieEnter}
        >
          {
            this.props.data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
          }
        </Pie>
      </PieChart>
    );
  }
}

export const getDurationDisplay = (duration) => {
  let minutes = Math.floor(duration / 60)
  const hours = Math.floor(minutes / 60)
  minutes = minutes % 60
  const seconds = duration % 60
  let dh, dm, ds
  if (hours && hours < 10) {
    dh = `0${hours}:`
  } else if (hours) {
    dh = `${hours}:`
  } else {
    dh = '00:'
  }
  if (minutes && minutes < 10) {
    dm = `0${minutes}:`
  } else if (minutes) {
    dm = `${minutes}:`
  } else {
    dm = '00:'
  }
  if (seconds && seconds < 10) {
    ds = `0${seconds}`
  } else if (seconds) {
    ds = `${seconds}`
  } else {
    ds = '00'
  }
  return `${hours ? dh : ''}${dm}${ds}`
}

class AgentReport extends React.Component {
  state = {
    agent_report_data: [],
    loading: true,
    selected_date: moment()
  }

  loadAgentReport = (selected_date) => {
    console.log("**************************** loadAgentReport ***************************")
    console.log(selected_date);

    this.setState({
      loading: true
    });
    let h_axios = prepAxiosHeaders();
    let api_url = '/api/agent/agent_report/'
    let api_url_filter = api_url + selected_date + '/'

    h_axios.get(window._env_.REACT_APP_API_URL + api_url_filter)
      .then(res => {
        console.log(res.data.data)
        this.setState({
          agent_report_data: res.data.data,
          loading: false
        });
      })
      .catch(err => {
        this.setState({
          loading: false
        });
        console.log("Error loading...");
      })
  }

  componentDidMount() {
    console.log("----------------------------------------------------");
    console.log(this.state.selected_date.format('YYYY-MM-DD'));
    console.log("----------------------------------------------------");
    this.loadAgentReport(this.state.selected_date.format('YYYY-MM-DD'))
  }

  handleChangeDatePicker = value => {
    if (value) {
      this.setState({
        selected_date: value
      });
      console.log("-------------------- NEW DATE ------------------------");
      console.log(value.format('YYYY-MM-DD'));
    }
  };

  handleButtonFilter = value => {
    console.log("filtering...")
    this.loadAgentReport(this.state.selected_date.format('YYYY-MM-DD'))
  }

  render() {
    let total_call = this.state.agent_report_data['total_call']
    let total_answered = this.state.agent_report_data['total_answered']
    let total_unanswered = this.state.agent_report_data['total_unanswered']
    let total_talk_time = getDurationDisplay(this.state.agent_report_data['total_talk_time'])
    let avg_talk_time = getDurationDisplay(this.state.agent_report_data['avg_talk_time'])
    let max_talk_time = getDurationDisplay(this.state.agent_report_data['max_talk_time'])
    let answer_ratio = this.state.agent_report_data['answer_ratio']

    let data = [
      { name: i18next.t('Answered call'), value: this.state.agent_report_data['total_answered'] },
      { name: i18next.t('Unanswered call'), value: this.state.agent_report_data['total_unanswered'] },
    ];

    return (
      <div>
        <Translation>{t => <h2>{t('Agent report')}</h2>}</Translation>
        <Space direction="vertical" size={20} style={{ width: '100%' }}>
          <Space direction="horizontal" size={20} style={{ width: '100%' }}>
            <DatePicker
              onChange={this.handleChangeDatePicker}
              defaultValue={this.state.selected_date}
              style={{ marginBottom: 8 }}
            />
            <Button onClick={this.handleButtonFilter} type="primary" htmlType="submit" size="middle" icon={<SearchOutlined />} style={{ marginBottom: '6px' }}>{i18next.t("Filter")}</Button>
          </Space>

          {
            this.state.loading ?
              <center><Spin size="large" /></center>
              :
              <div>
                <Row gutter={16}>
                  <Col span={8}>
                    <Card>
                      <Statistic
                        title={i18next.t("Total call")}
                        value={total_call}
                        precision={0}
                        valueStyle={{ color: blue[3] }}
                        prefix={<PhoneOutlined />}
                        suffix={i18next.t("calls")}
                      />
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card>
                      <Statistic
                        title={i18next.t("Answered call")}
                        value={total_answered}
                        precision={0}
                        valueStyle={{ color: green[3] }}
                        prefix={<PhoneOutlined />}
                        suffix={i18next.t("calls")}
                      />
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card>
                      <Statistic
                        title={i18next.t("Unanswered call")}
                        value={total_unanswered}
                        precision={0}
                        valueStyle={{ color: red[3] }}
                        prefix={<IssuesCloseOutlined />}
                        suffix={i18next.t("calls")}
                      />
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card>
                      <Statistic
                        title={i18next.t("Total talk time")}
                        value={total_talk_time}
                        precision={0}
                        valueStyle={{ color: gold[4] }}
                        prefix={<CustomerServiceOutlined />}
                        suffix={i18next.t("min")}
                      />
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card>
                      <Statistic
                        title={i18next.t("Avg. talk time")}
                        value={avg_talk_time}
                        precision={0}
                        valueStyle={{ color: gold[4] }}
                        prefix={<CustomerServiceOutlined />}
                        suffix={i18next.t("min")}
                      />
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card>
                      <Statistic
                        title={i18next.t("Max talk time")}
                        value={max_talk_time}
                        precision={0}
                        valueStyle={{ color: gold[4] }}
                        prefix={<CustomerServiceOutlined />}
                        suffix={i18next.t("min")}
                      />
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card>
                      <Statistic
                        title={i18next.t("Answer Ratio")}
                        value={answer_ratio}
                        precision={0}
                        valueStyle={{ color: blue[3] }}
                        prefix={<PhoneOutlined />}
                        suffix="%"
                      />
                    </Card>
                  </Col><Col span={16}>
                    <Card>
                      <Text type="secondary">{i18next.t("Answered / Unanswered")}</Text>
                      <DonutChart data={data} />
                    </Card>
                  </Col>
                </Row>

              </div>
          }

        </Space>
      </div>
    );
  }
}

export default AgentReport;
