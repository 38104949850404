import React from 'react';
import { phoneStore } from 'react-sip-phone'
import { Table, Spin, Input, Button, Tag } from 'antd';
import { DatePicker, Space } from 'antd';
import CallbackLaterStatusTag from '../components/CallbackLaterStatusTag'
import { CallbackEdit } from '../components/CallbackLater'
import Highlighter from 'react-highlight-words';
import { SearchOutlined, PhoneOutlined } from '@ant-design/icons';
import moment from 'moment';
import { prepAxiosHeaders } from '../store/utility';
import { Translation } from 'react-i18next';
import i18next from 'i18next';

//
// Sample data
//
// const callback_data = [
//     {
//       "key": 1,
//       "phone_number": "+19493383173",
//       "status_name": "Pending",
//       "full_name": null,
//       "status": 1,
//       "campaign_name": "Voice Campaign, Nov 11, 11:02",
//       "disposition": "",
//       "callback_date": "2020-12-04T15:18:54Z"
//   },
//   {
//       "key": 2,
//       "phone_number": "+12342001234",
//       "status_name": "Pending",
//       "full_name": null,
//       "status": 1,
//       "campaign_name": "Voice Campaign, Nov 11, 11:02",
//       "disposition": "",
//       "callback_date": "2020-12-04T15:18:33Z"
//   }
// ]

class CallbackLater extends React.Component {
  state = {
    callback_later_data: [],
    loading: true,
    error: false,
    searchText: '',
    searchedColumn: '',
    from_date: moment(),
    to_date: moment().add(1, 'days')
  }

  loadCallbackLater = (from_date, to_date) => {
    console.log("**************************** loadCallbackLater ***************************")
    console.log(from_date);
    console.log(to_date);

    this.setState({
      loading: true
    });
    let h_axios = prepAxiosHeaders();
    let api_url = '/api/agent/list_callback/'
    let api_url_filter = api_url + from_date + '/' + to_date + '/'

    h_axios.get(window._env_.REACT_APP_API_URL + api_url_filter)
      .then(res => {
        this.setState({
          callback_later_data: res.data.data,
          loading: false
        });
      })
      .catch(err => {
        this.setState({
          loading: false
        });
        console.log("Error loading...");
      })
  }

  componentDidMount() {
    console.log("----------------------------------------------------");
    console.log(this.state.from_date.format('YYYY-MM-DD'));
    console.log("----------------------------------------------------");
    this.loadCallbackLater(this.state.from_date.format('YYYY-MM-DD'), this.state.to_date.format('YYYY-MM-DD'))
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {i18next.t("Search")}
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            {i18next.t("Reset")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
          text
        ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };


  handleChangeDatePicker = value => {
    if (value && value[0] && value[1]) {
      this.setState({
        from_date: value[0],
        to_date: value[1]
      });

      console.log("-------------------- NEW DATE RANGE------------------------");
      console.log(value[0].format('YYYY-MM-DD'), value[1].format('YYYY-MM-DD'));
    }
  };

  handleButtonFilter = value => {
    console.log("filtering...")
    this.loadCallbackLater(this.state.from_date.format('YYYY-MM-DD'), this.state.to_date.format('YYYY-MM-DD'))
  }

  handleCallbackButton = (subscriber_id, phone_number) => {
    console.log("*************** // handleCallbackButton // *****************")
    console.log(subscriber_id, phone_number);
    // subscriber_id = 620456
    const sipAccount = phoneStore.getState().sipAccounts.sipAccount
    console.log(sipAccount)
    if (sipAccount && phone_number) {
      const InviterOptions = {
        extraHeaders: [
          `X-AgentUI-Phone-Number: ${phone_number}`,
          `X-AgentUI-Subscriber-ID: ${subscriber_id}`
        ]
      }
      sipAccount.makeCall(phone_number, InviterOptions)
    }
  };

  render() {
    const webrtc_enabled = localStorage.getItem('enable_webrtc') === 'true' ? true : false;
    const columns = [
      {
        title: i18next.t("Callback date"),
        dataIndex: 'callback_date',
        key: 'callback_date',
        render: callback_date => {
          let local_date = new Date(callback_date)
          return (
            <span>
              {moment(local_date).format('lll')}
            </span>
          )
        },
      },
      {
        title: i18next.t("Phone number"),
        dataIndex: 'phone_number',
        key: 'phone_number',
        ...this.getColumnSearchProps('phone_number'),
        // render: text => <a href={hrefLink}>{text}</a>,
      },
      {
        title: i18next.t("Contact Name"),
        dataIndex: 'full_name',
        key: 'full_name',
        ...this.getColumnSearchProps('full_name'),
      },
      {
        title: i18next.t("Disposition"),
        key: 'disposition',
        dataIndex: 'disposition',
        render: disposition => (
          <Tag color="geekblue">{disposition}</Tag>
        ),
      },
      {
        title: i18next.t("Campaign"),
        dataIndex: 'campaign_name',
        key: 'campaign_name',
      },
      {
        title: i18next.t("Status"),
        key: 'status_name',
        dataIndex: 'status_name',
        ...this.getColumnSearchProps('status_name'),
        render: status_name => (
          <span>
            <CallbackLaterStatusTag status_name={status_name} />
          </span>
        ),
      },
      {
        title: i18next.t("Action"),
        key: 'action',
        render: (text, record) => {
          let display_callback = true;
          if (!webrtc_enabled) {
            display_callback = false;
          }
          if (record.phone_number && record.phone_number.indexOf('*') > -1) {
            console.log("Callback disabled as there is * in the phone number: ", record.phone_number);
            display_callback = false;
          }

          return (
            <Space direction="horizontal" size={5}>
            {/* <a href={hrefLink}>Callback {record.phone_number}</a> */}

            <CallbackEdit
              callback_id={record.key}
              subscriber_id={record.subscriber_id}
              status={record.status}
              full_name={record.full_name}
              phone_number={record.phone_number}
              callback_date={record.callback_date}
              postFuncReload={() => this.loadCallbackLater(this.state.from_date.format('YYYY-MM-DD'), this.state.to_date.format('YYYY-MM-DD'))}
            />
              {display_callback &&
              <Button onClick={() => this.handleCallbackButton(record.subscriber_id, record.phone_number)} icon={<PhoneOutlined />} type="primary" size="small">{i18next.t("Callback")}</Button>
            }
          </Space>
          );
        },
      },
    ];
    return (
      <div>
        <Translation>{t => <h2>{t('Callback Later')}</h2>}</Translation>
        <Space direction="vertical" size={20} style={{ width: '100%' }}>
          <Space direction="horizontal" size={20} style={{ width: '100%' }}>
            <DatePicker.RangePicker
              onCalendarChange={this.handleChangeDatePicker}
              defaultValue={[this.state.from_date, this.state.to_date]}
              style={{ marginBottom: 8 }}
            />
            <Button onClick={this.handleButtonFilter} type="primary" htmlType="submit" size="middle" icon={<SearchOutlined />} style={{ marginBottom: '6px' }}>{i18next.t("Filter")}</Button>
          </Space>

          {
            this.state.loading ?
              <center><Spin size="large" /></center>
              :
              <Table columns={columns} dataSource={this.state.callback_later_data} pagination={{ pageSize: 5 }} />
          }

        </Space>
      </div>
    );
  }

}

export default CallbackLater;
