import React from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import { Spin, Button, Space, Row, Col } from 'antd';
import { Empty } from 'antd';
import { SoundOutlined, PlayCircleOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { green, red } from '@ant-design/colors';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { prepAxiosHeaders } from '../store/utility';
import i18next from 'i18next';


class Soundboard extends React.Component {
  state = {
    loading: false,
  }

  componentDidMount() {
    console.log("----------------------------------------------------");
  }

  handleButtonPlay = (audio_id) => {
    // http://127.0.0.1:8000/api/agent/broadcast_audio/1/
    console.log(`Play audio: ${audio_id}`)
    this.setState({
      loading: true
    });
    let h_axios = prepAxiosHeaders();
    let api_url_broadcast = '/api/agent/broadcast_audio/' + audio_id + '/'

    h_axios.get(window._env_.REACT_APP_API_URL + api_url_broadcast)
      .then(res => {
        console.log(res.data.data)
        this.setState({
          loading: false
        });
        if (audio_id === '0') {
          message.success(i18next.t("Audio stopped!"), 1);
        } else {
          message.success(i18next.t("Audio played to the contact!"), 1);
        }
      })
      .catch(err => {
        this.setState({
          loading: false
        });
        message.error(i18next.t("Error playing audio to contact!"), 1);
        console.log("Error loading...");
      })
  }

  render() {
    let counter = 0;
    let count_elem = 0;
    let keys = []
    const soundboard_audio = this.props.soundboard_audio;
    if (soundboard_audio) {
      keys = Object.keys(soundboard_audio);
      count_elem = keys.length;
    }
    return (
      <div>
        <h2>{i18next.t("Soundboard")}
          <Button
            onClick={() => this.handleButtonPlay("0")}
            size="middle" shape="round" icon={<CloseSquareOutlined />} style={{ "backgroundColor": red[4], "color": "#FFFFFF", "margin": "25px 5px 5px 5px" }}>
            {i18next.t("Stop Audio")}
          </Button>
        </h2>
        <Space direction="vertical" size={10} style={{ width: '100%' }}>
          {
            this.state.loading ?
              <center><Spin size="large" /></center>
              :
              <div>
                {
                  (count_elem > 0)
                    ?
                    <Row gutter={24}>
                    {
                      keys.map(item => {
                        counter = counter + 1;
                        let audio_url = window._env_.REACT_APP_API_URL + soundboard_audio[item].audio_url;
                        return (
                          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
                            <div className="soundboard-card">
                              <h4><SoundOutlined /> {soundboard_audio[item].audio_name}</h4>
                              <AudioPlayer
                                src={audio_url}
                                onPlay={e => console.log("onPlay")}
                                showSkipControls={false}
                                showJumpControls={false}
                                showFilledVolume={false}
                                autoPlay={false}
                                autoPlayAfterSrcChange={false}
                                // muted={true}
                                customAdditionalControls={[]}
                                layout="horizontal"
                              />

                              <Button
                                onClick={() => this.handleButtonPlay(soundboard_audio[item].audio_id)}
                                size="middle" shape="round" icon={<PlayCircleOutlined />} style={{ "backgroundColor": green[4], "color": "#FFFFFF", "margin": '15px 0px 0px 0px' }}>
                                {i18next.t("Play to Contact")}
                              </Button>
                            </div>
                          </Col>
                        );
                      })
                      }
                    </Row>
                    :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
              </div>
          }
        </Space>
      </div >
    );
  }
}


const mapStateToProps = state => {
  return {
    agent_wrap_up_time: state.agent.agent_wrap_up_time,
    soundboard_audio: state.agent.soundboard_audio,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // fetchContact: () => dispatch(actions.fetchContact())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Soundboard);
