import React from 'react';
import { Table } from 'antd';
import i18next from 'i18next';

const columns = [
  {
    title: i18next.t('Extension'),
    dataIndex: 'extension',
  },
  {
    title: i18next.t('Description'),
    dataIndex: 'description',
  },
];
const data = [
  {
    key: '1',
    extension: '1000',
    description: i18next.t('Log out of the queue to stop receiving calls.'),
  },
  {
    key: '2',
    extension: '1001',
    description: i18next.t('Log in to the queue to manually answer calls.'),
  },
  {
    key: '3',
    extension: '1002',
    description: i18next.t('Standby: Call into the queue to automatically answer calls instantly while listening to Music on Hold.'),
  },
  {
    key: '4',
    extension: '1004',
    description: i18next.t('Standby-Silence: As 1002, automatically answering calls instantly with no Music on Hold.'),
  },
  {
    key: '5',
    extension: '0001',
    description: i18next.t('Echo Test: Test and confirm 2 way audio via microphone and headset.'),
  },
  {
    key: '6',
    extension: '0002',
    description: i18next.t('Milliwatt Test Tone: Continuous test tone to confirm audio quality.'),
  },
];

function PopoverDialExtension() {
  return <div>
    <h3>{i18next.t('Extensions available')}:</h3>
    <Table
      columns={columns}
      dataSource={data}
      showHeader={false}
      pagination={false}
      size="small" />
  </div>
}

export default PopoverDialExtension;