import React from 'react';
import { connect } from 'react-redux';
import { Button, Tooltip } from 'antd';
import * as actions from '../store/actions/agent';
import { red } from '@ant-design/colors';
import { message } from 'antd';
import { StopOutlined } from '@ant-design/icons';
import i18next from 'i18next';


class SingleHangupButton extends React.Component {

  handleClickHangupButton = e => {
    let subscriber_id = false;
    if (this.props.contact_data) {
      subscriber_id = this.props.contact_data.subscriber_id;
    } else {
      return false;
    }

    console.log("hangup subscriber_id: ", subscriber_id);
    actions.hangupCurrentCall(subscriber_id);
    message.error(i18next.t("Hangup signal sent!"), 1);
  };

  render() {
    let subscriber_id = false;
    if (this.props.contact_data) {
      subscriber_id = this.props.contact_data.subscriber_id;
    }
    return (
      <div className="center-box">
        {
          subscriber_id
            ?
            <Tooltip placement="topLeft" title={i18next.t("Hangup")}>
              <Button
                icon={<StopOutlined />}
                size={'default'}
                style={{ "backgroundColor": red[3], "color": "#FFFFFF" }}
                onClick={this.handleClickHangupButton}>
                {i18next.t("Hangup")}
              </Button>
            </Tooltip>
            :
            <span></span>

        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    // token: state.auth.token,
    contact_data: state.contact.contact_data
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleHangupButton);
