import React from 'react';
import { Timeline } from 'antd';
import { ClockCircleOutlined, UserOutlined, MessageOutlined, PhoneOutlined, LockOutlined } from '@ant-design/icons';


// Move to his own components
class ContactTimeline extends React.Component {
  render() {
    // console.log(this.props.data);
    // Todo: see if we can have a cleaner unique key
    let counter = 0;
    return (
      <div>
        <Timeline mode="alternate">
          {
            this.props.data.map(item => {
              counter = counter + 1;
              // let color = item == 5 ? 'geekblue' : 'green';
              let dot = '';
              let color = 'blue';

              if (item.label === 'NOTE ADDED') {
                dot = <MessageOutlined style={{ fontSize: '16px' }} />
              }
              if (item.label === 'CALLBACK') {
                color = 'green';
                dot = <ClockCircleOutlined type="clock-circle-o" style={{ fontSize: '16px' }} />
              }

              if (item.label === 'INTERESTED') {
                color = 'green';
                dot = <UserOutlined type="user-o" style={{ fontSize: '16px' }} />
              }
              if (item.label === 'CALL_SUCCESSFUL') {
                color = 'green';
                dot = <PhoneOutlined style={{ fontSize: '16px' }} />
              }
              // TODO: Check if contains failed in any string, set to red
              if (item.label === 'CALL FAILED' || item.label === 'FAILED') {
                color = 'red';
                dot = <LockOutlined style={{ fontSize: '16px' }} />
              }
              // let itemText = item.label.toUpperCase() + item.label.toUpperCase();
              return (
                <Timeline.Item
                  key={counter}
                  color={color}
                  dot={dot}>
                  <b>{item.created_date} - {item.label.toUpperCase()}</b> <br />
                  {item.event_type}
                </Timeline.Item>
              );
            })}
        </Timeline>
      </div>
    );
  }
}


export default ContactTimeline;
