import React from 'react';
import { Avatar, Select, Badge, Popover, Tag } from 'antd';
import { connect } from 'react-redux';
import * as actions from '../store/actions/agent';
import { UserOutlined } from '@ant-design/icons';
import i18next from 'i18next';

const { Option } = Select;


const AGENT_STATUS_LOGOUT = 1;
const AGENT_STATUS_AVAILABLE = 2;
// const AGENT_STATUS_ON_DEMAND = 3;
const AGENT_STATUS_ON_BREAK = 4;
// const AGENT_STATUS_UNKNOWN = 0;

const AGENT_STATUS_TEXT_MAPPING = {
  0: i18next.t("Unknown"),
  1: i18next.t("Logged Out"),
  2: i18next.t("Available"),
  3: i18next.t("On Standby"),
  4: i18next.t("On Break"),
};

const COLOR_MAPPING_ICON = {
  0: '#40a9ff',
  1: '#ff4d4f',
  2: '#73d13d',
  3: '#87e8de',
  4: '#ffa940',
};

const COLOR_MAPPING = {
  0: 'geekblue',
  1: 'red',
  2: 'green',
  3: 'cyan',
  4: 'volcano',
};

function PopoverContent(username, password) {
  return <div>
    {i18next.t("Please connect your SIP phone!")}<br />
    {i18next.t("Username")}: <b>{username}</b> <br />
    {i18next.t("Password")}: <b>{password}</b>
  </div>
}

class RegisteredButton extends React.Component {
  render() {
    return (
      <span>
        {
          this.props.is_registered
            ?
            <Badge status="success" text={i18next.t("Registered")} />
            :
            <Popover content={PopoverContent(this.props.username, this.props.password)}>
              <Badge status="error" text={i18next.t("Not registered")} />
            </Popover>
        }
        &nbsp;&nbsp;
      </span>
    );
  }
}


class AgentStatus extends React.Component {

  tickAgentStatusLoad = () => {
    this.props.fetchAgentStatus();
  };

  componentDidMount() {
    this.props.fetchAgentStatus();
    this.timerID = setInterval(this.tickAgentStatusLoad, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  handleChange = (value) => {
    this.props.updateAgentStatus(value);
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  render() {
    let change_status = false;
    // Disable change status when agent is on Standby
    if (this.props.agent_status === 3) {
      change_status = true;
    }
    return (
      <div className="div_welcome_user">
        <RegisteredButton is_registered={this.props.agent_registered} username={this.props.phone_login} password={this.props.phone_password} />

        <Tag color={COLOR_MAPPING[this.props.agent_status]}> {AGENT_STATUS_TEXT_MAPPING[this.props.agent_status]} </Tag>

        <Select placeholder={i18next.t("Set status")} style={{ width: 120 }} onChange={this.handleChange} disabled={change_status}>
          <Option value={AGENT_STATUS_AVAILABLE}>{i18next.t("Available")}</Option>
          <Option value={AGENT_STATUS_ON_BREAK}>{i18next.t("On Break")}</Option>
          <Option value={AGENT_STATUS_LOGOUT}>{i18next.t("Log Out")}</Option>
        </Select>
        &nbsp;&nbsp;
        <Avatar
          style={{ backgroundColor: COLOR_MAPPING_ICON[this.props.agent_status] }}
          icon={<UserOutlined />}
        /> {this.capitalizeFirstLetter(this.props.username)}
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    agent_status: state.agent.agent_status,
    agent_registered: state.agent.agent_registered,
    phone_login: state.auth.phone_login,
    phone_password: state.auth.phone_password,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchAgentStatus: () => dispatch(actions.fetchAgentStatus()),
    updateAgentStatus: (status) => dispatch(actions.updateAgentStatus(status)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentStatus);
