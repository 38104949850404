import * as actionTypes from './actionType';
import axios from 'axios';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  }
}

export const authSuccess = (token, username, phone_login, phone_password, enable_webrtc, enable_webrtc_autoanswer) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    username: username,
    phone_login: phone_login,
    phone_password: phone_password,
    enable_webrtc: enable_webrtc,
    enable_webrtc_autoanswer: enable_webrtc_autoanswer,
    token: token
  }
}

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  }
}

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('phone_login');
  localStorage.removeItem('phone_password');
  localStorage.removeItem('enable_webrtc');
  localStorage.removeItem('enable_webrtc_autoanswer');
  // localStorage.removeItem('expirationDate');
  return {
    type: actionTypes.AUTH_LOGOUT
  };
}

// export const checkAuthTimeout = expirationTime => {
//   return dispatch => {
//     setTimeout(() => {
//       console.log("checkAuthTimeout -> setTimeout:", expirationTime);
//       dispatch(logout());
//     }, expirationTime * 1000)
//   }
// }

export const authLogin = (username, password) => {
  return dispatch => {
    dispatch(authStart());
    axios.post(
      window._env_.REACT_APP_API_URL + '/api/agent/login', {
      username: username,
      password: password,
    })
      .then(res => {
        // Todo: use proper token for authentication
        // const token = res.data.key;
        // console.log(res.data.agent.phone_login);
        const token = username + '|' + password
        // one hour in the future
        localStorage.setItem('token', token);
        localStorage.setItem('phone_login', res.data.agent.phone_login);
        localStorage.setItem('phone_password', res.data.agent.phone_password);
        localStorage.setItem('enable_webrtc', res.data.agent.enable_webrtc);
        localStorage.setItem('enable_webrtc_autoanswer', res.data.agent.enable_webrtc_autoanswer);
        // const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
        // Let's disable the expiration date
        // localStorage.setItem('expirationDate', expirationDate);
        // console.log("authLogin -> expirationDate:", expirationDate);
        // console.log('authSuccess(token):' + token);
        dispatch(authSuccess(token, username, res.data.agent.phone_login, res.data.agent.phone_password, res.data.agent.enable_webrtc, res.data.agent.enable_webrtc_autoanswer));
        // dispatch(checkAuthTimeout(3600));
      })
      .catch(err => {
        dispatch(authFail(err))
      })
  }
}

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('token');
    const phone_login = localStorage.getItem('phone_login');
    const phone_password = localStorage.getItem('phone_password');
    const enable_webrtc = localStorage.getItem('enable_webrtc');
    const enable_webrtc_autoanswer = localStorage.getItem('enable_webrtc_autoanswer');
    if (token) {
      let res = token.split('|');
      let username = res[0];
      // const expirationDate = new Date(localStorage.getItem('expirationDate'));
      // if (expirationDate <= new Date()) {
      //   dispatch(logout())
      // } else {
      dispatch(authSuccess(token, username, phone_login, phone_password, enable_webrtc, enable_webrtc_autoanswer))
      //   dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
      // }
    } else {
      dispatch(logout());
    }
  }
}