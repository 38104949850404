import React from 'react';
import { Form, Button, Divider, Modal, DatePicker, Select, Space } from 'antd';
import { connect } from 'react-redux';
import { EditOutlined, CalendarOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import * as actions from '../store/actions/contact';
import moment from 'moment';
// import { message } from 'antd';
import i18next from 'i18next';


class CallbackLaterEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      callback_edit: false,
      confirmLoading: false,
      original_date: null,
      original_status: null,
      new_date: null,
      new_status: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.callback_id !== this.props.callback_id) {
      this.setState({
        original_date: null,
        original_status: null,
        new_date: null,
        new_status: null,
        callback_edit: false,
      });
    }
  }

  componentDidMount() {
    console.log("----------- MOUNT -------------");
    console.log(this.props.callback_date, this.props.status)
    let callback_date = moment(this.props.callback_date)
    this.setState({
      original_status: this.props.status,
      original_date: callback_date,
    });
    console.log("----------- END MOUNT -------------");
  }

  setConfirmLoading = (value) => {
    this.setState({
      confirmLoading: value,
    });
  };

  handleonSelectUpdate = new_status => {
    this.setState({
      new_status: new_status
    });
  };

  handleChangeDatePicker = new_date => {
    console.log("handleChangeDatePicker")
    console.log(new_date)
    this.setState({
      new_date: new_date
    });
  };

  handleOk = () => {
    console.log("---------------- handleOK -----------------");
    console.log(this.state.original_date, this.state.original_status)
    console.log(this.state.new_date, this.state.new_status)
    console.log("---------------- END handleOK -----------------");
    if (!this.state.new_date && !this.state.new_status) {
      console.log('Callback Not updated!')
      // message.success('Callback Not updated!', 1);
    } else if (this.state.new_date === this.state.original_date && this.state.new_status === this.state.original_status) {
      console.log('Same value for callback!')
      // message.success('Callback Not updated!', 1);
    } else {
      let formatted_date = ''
      if (!this.state.new_date) {
        formatted_date = this.state.original_date.format('YYYY-MM-DD HH:mm:ss')
      } else {
        formatted_date = this.state.new_date.format('YYYY-MM-DD HH:mm:ss')
      }
      let status = this.state.original_status
      if (this.state.new_status) {
        status = this.state.new_status
      }
      console.log("formatted_date:", formatted_date)
      console.log("status:", status)
      this.setConfirmLoading(true)
      // Call API to Update Callback
      this.props.updateCallback(this.props.callback_id, formatted_date, status)
    }

    setTimeout(() => {
      this.setState({
        visible: false,
      });
      this.setConfirmLoading(false);
      this.props.postFuncReload()
    }, 500);
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  // <CallbackLaterEdit callback_id={record.key} subscriber_id={record.subscriber_id} status={record.status} full_name={record.full_name} phone_number={record.phone_number} callback_date={record.callback_date} />
  render() {
    let local_date = new Date(this.props.callback_date)
    let local_moment = moment(local_date, 'YYYY-MM-DD')
    return (
      <div>
        {
          this.state.callback_edit ?

            <Divider />
            :
            <>
              <Button onClick={this.showModal} icon={<EditOutlined />} type="primary" size="small">
                {i18next.t("Edit")}
              </Button>
              <Modal
                title={i18next.t("Edit callback later")}
                visible={this.state.visible}
                // onOk={this.hideModal}
                onCancel={this.hideModal}
                cancelText={i18next.t("Cancel")}
                onOk={this.handleOk}
                confirmLoading={this.state.confirmLoading}
                okText={i18next.t("Submit")}
              >
                <div>
                  <p><b>{i18next.t("Full name")}:</b> {this.props.full_name}</p>
                  <p><b>{i18next.t("Phone number")}:</b> {this.props.phone_number}</p>
                  <p><b>{i18next.t("Current callback date")}:</b> {moment(local_date).format('lll')}</p>

                  <Form
                    initialValues={{ status: this.state.original_status }}
                  >
                    <Divider />
                    <Form.Item label={i18next.t("Callback date")} name="callback_date">
                      <DatePicker
                        onChange={this.handleChangeDatePicker}
                        format="YYYY-MM-DD HH:mm"
                        showTime={{ defaultValue: moment('00:00:00', 'HH:mm') }}
                        defaultValue={local_moment}
                        allowClear={false}
                      />
                    </Form.Item>
                    <Form.Item label={i18next.t("Status")} name="status">
                      {/* 1: Pending,  2: Connected,  3: Not Connected */}
                      <Select
                        style={{ width: '200px' }}
                        onSelect={this.handleonSelectUpdate}
                      >
                        <Select.Option value={1}>{i18next.t("Pending")}</Select.Option>
                        <Select.Option value={2}>{i18next.t("Connected")}</Select.Option>
                        <Select.Option value={3}>{i18next.t("Not Connected")}</Select.Option>
                      </Select>
                    </Form.Item>
                  </Form>
                </div>
              </Modal>
            </>
        }
      </div>
    );
  }
}


class CallbackLaterAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      callback_added: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.subscriber_id !== this.props.subscriber_id) {
      this.setState({
        callback_added: false,
      });
    }
  }
  handleFormSubmit = (value) => {
    console.log("//////////////////////////////////////////")
    console.log("value.callback_date: ", value.callback_date)
    let formatted_date = value.callback_date.format('YYYY-MM-DD HH:mm:ss');
    console.log("formatted_date:", formatted_date);
    // Call API to create Callback
    this.props.addCallback(this.props.subscriber_id, formatted_date)

    this.setState({
      callback_added: true,
    });

  }

  onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  render() {
    let local_date = new Date()
    let local_moment
    let day = moment(local_date, 'YYYY-MM-DD').day()
    if (day === 5) {
      // Friday add 3 days
      // Check https://www.npmjs.com/package/moment-business-days to do a better support
      local_moment = moment(local_date, 'YYYY-MM-DD').add(3, 'd')
    } else {
      local_moment = moment(local_date, 'YYYY-MM-DD').add(1, 'd')
    }
    return (
      <div>
        {
          this.state.callback_added ?
            <Space direction="horizontal" size={5} style={{ margin: 10 }}>
              <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '25px' }} />
              <h4>{i18next.t("Callback added!")}</h4>
            </Space>
            :
            <Space direction="vertical" size={1}>
              <h3>{i18next.t("Callback later")}:</h3>
              <Form
                onFinish={(value) => this.handleFormSubmit(value)}
                onFinishFailed={this.onFinishFailed}
                initialValues={{ callback_date: local_moment }}
              >
                <Form.Item name="callback_date" style={{ marginBottom: 5 }}>
                  <DatePicker
                    onChange={this.handleChangeDatePicker}
                    format="YYYY-MM-DD HH:mm"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm') }}
                    // defaultValue={local_moment}
                    allowClear={false}
                  />
                </Form.Item>
                <Button
                  onClick={this.showModal}
                  icon={<CalendarOutlined />} type="primary" size="middle" htmlType="submit">
                  {i18next.t("Add callback later")}
                </Button>
              </Form>
            </Space>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    callback_error: state.contact.callback_error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addCallback: (subscriber_id, callback_date) => dispatch(actions.addCallback(subscriber_id, callback_date)),
    updateCallback: (callback_id, callback_date, status) => dispatch(actions.updateCallback(callback_id, callback_date, status)),
  }
}

export const CallbackEdit = connect(mapStateToProps, mapDispatchToProps)(CallbackLaterEdit);
export const CallbackAdd = connect(mapStateToProps, mapDispatchToProps)(CallbackLaterAdd);
