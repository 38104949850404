import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Button, Tooltip, Form, Col, Row, Select } from 'antd';
import * as actions from '../store/actions/agent';
import { orange } from '@ant-design/colors';
import { message } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import i18next from 'i18next';

const { Option } = Select;


class XferButton extends React.Component {

  state = {
    size: 'default',
    visible: false,
    xfer_type: 'cold',
    xfer_loading: false
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  HandleTickResetAllXfer = () => {
    this.setState({
      xfer_loading: false
    });
    clearInterval(this.timerID);
  };

  handleSubmitXfer = values => {
    // console.log('Accepted values on Form: ', values);
    console.log(values);
    const xfer_type = values['transfer_type'];
    let subscriber_id = false;
    if (this.props.contact_data) {
      subscriber_id = this.props.contact_data.subscriber_id;
    } else {
      return false;
    }
    console.log('xfer_type: ', xfer_type);
    const transfer_to = '##' + values['transfer_to'];

    console.log("xfer subscriber_id: " + subscriber_id + " - transfer_to:" + transfer_to);
    if (xfer_type === 'cold') {
      actions.XferCall(subscriber_id, 'cold_xfer', transfer_to);
      message.success(i18next.t("Cold Xfer requested!"), 1);
    } else if (xfer_type === 'warm') {
      actions.XferCall(subscriber_id, 'warm_xfer', transfer_to);
      message.success(i18next.t("Warm Xfer requested!"), 1);
    } else if (xfer_type === '3way') {
      actions.XferCall(subscriber_id, '3way_xfer', transfer_to);
      message.success(i18next.t("3Way Xfer requested!"), 1);
    }
    this.timerID = setInterval(this.HandleTickResetAllXfer, 5000);
    this.setState({
      visible: false,
      xfer_loading: true,
    });
  };

  OpenDrawerXfer = e => {
    this.setState({
      xfer_type: 'cold',
    });
    this.showDrawer();
  };

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    let list_key_transfer = false;
    let subscriber_id = false;
    if (this.props.contact_data) {
      list_key_transfer = this.props.contact_data.callcenter_config.key_transfer;
      subscriber_id = this.props.contact_data.subscriber_id;
    }
    const { size } = this.state;
    let counter = 0;
    const keys = Object.keys(list_key_transfer);
    return (
      <div className="center-box">
        {
          list_key_transfer && subscriber_id
            ?
            <div>
              <Tooltip placement="topLeft" title={i18next.t("Call Transfer")}>
                <Button
                  icon={<DoubleRightOutlined />}
                  size={size} loading={this.state.xfer_loading}
                  style={{ "backgroundColor": orange[3], "color": "#FFFFFF" }}
                  onClick={this.OpenDrawerXfer}>
                  {i18next.t("Xfer")}
                </Button>
              </Tooltip>

              <Drawer
                title={i18next.t("Transfer")}
                width={300}
                onClose={this.onClose}
                visible={this.state.visible}
              >
                <Form
                  name="phoneaction_form"
                  layout="vertical"
                  hideRequiredMark
                  initialValues={{ transfer_type: "cold" }}
                  onFinish={this.handleSubmitXfer}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label={i18next.t("Transfer type")} name="transfer_type">
                        <Select name="transfer_type" placeholder="Select to the type of transfer">
                          <Option value="cold">{i18next.t("Cold")}</Option>
                          {/* <Option value="warm">{i18next.t("Warm")}</Option> */}
                          <Option value="3way">{i18next.t("3Way")}</Option>
                        </Select>
                      </Form.Item>

                      <Form.Item label={i18next.t("Transfer to")} name="transfer_to" rules={[{ required: true, message: i18next.t("Select to whom to transfer") }]}>
                        <Select name="transfer_to" placeholder={i18next.t("Select to whom to transfer")}>
                          {
                            keys.map(item => {
                              counter = counter + 1;
                              return (
                                <Option value={item} key={counter}>
                                  {list_key_transfer[item]}
                                </Option>
                              );
                            })
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Button type="primary" htmlType="submit">{i18next.t("Transfer")}</Button>
                    </Col>
                  </Row>
                </Form>
                <div
                  style={{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                  }}
                >
                  <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                    {i18next.t("Cancel")}
                </Button>
                </div>
              </Drawer>
            </div>
            :
            <span></span>

        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contact_data: state.contact.contact_data
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(XferButton);