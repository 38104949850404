import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from "react-router-dom";
import Lastcalls from './containers/LastcallView';
import AgentReport from './containers/AgentReportView';
import CallbackLater from './containers/CallbackLaterView';
// import Leadboard from './containers/LeadboardView';
import ContactView from './containers/ContactView';
import Soundboard from './containers/SoundboardView';

const BaseRouter = () => (
  <div>
    <Route exact path='/' component={ContactView} />
    <Route path='/callbacklater' component={CallbackLater} />
    <Route path='/lastcalls' component={Lastcalls} />
    <Route path='/agent_report' component={AgentReport} />
    <Route path='/soundboard' component={Soundboard} />
    {/* <Route path='/leadboard' component={Leadboard} /> */}
    <Route
      exact
      path='/logout'
      render={() => (
        <div>
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        </div>
      )
      }
    />

  </div>
);

export default BaseRouter;