import * as actionTypes from './actionType';
import { message } from 'antd';
import { prepAxiosHeaders } from '../utility';
import i18next from 'i18next';

//
// ---- Load Agent Status ----
//
export const loadAgentStatusStarted = () => {
  return {
    type: actionTypes.LOAD_AGENT_STATUS_STARTED
  }
}

export const loadAgentStatusDone = (agent_status, agent_registered, agent_wrap_up_time, soundboard_audio) => {
  return {
    type: actionTypes.LOAD_AGENT_STATUS_DONE,
    agent_status: agent_status,
    agent_registered: agent_registered,
    agent_wrap_up_time: agent_wrap_up_time,
    soundboard_audio: soundboard_audio,
  }
}

export const loadAgentStatusFailed = error => {
  return {
    type: actionTypes.LOAD_AGENT_STATUS_FAILED,
    agent_status_error: error
  }
}

// Load AgentStatus
export const fetchAgentStatus = () => {
  return dispatch => {
    dispatch(loadAgentStatusStarted());
    let h_axios = prepAxiosHeaders();
    if (h_axios) {
      h_axios.get(window._env_.REACT_APP_API_URL + '/api/agent/get_agent_status/')
        .then(res => {
          if (res.data.status === 'OK') {
            dispatch(loadAgentStatusDone(
              res.data.data.agent_status_numeric,
              res.data.data.agent_registered,
              res.data.data.agent_wrap_up_time,
              res.data.data.soundboard_audio
            ));
          } else {
            dispatch(loadAgentStatusFailed(res.data.status))
          }
        })
        .catch(err => {
          dispatch(loadAgentStatusFailed(err))
          console.log("Error loading...");
        })
    } else {
      dispatch(loadAgentStatusFailed('Axios headers error...'))
    }
  }
}


//
// ---- Update Agent Status ----
//

export const updateAgentStatusStarted = () => {
  return {
    type: actionTypes.UPDATE_AGENT_STATUS_STARTED
  }
}

export const updateAgentStatusDone = agent_status => {
  switch (agent_status) {
    case 1:
      message.success(i18next.t("You are now logged out!"), 1);
      break;
    case 2:
      message.success(i18next.t("You are now available!"), 1);
      break;
    case 3:
      message.success(i18next.t("You are now available on demand!"), 1);
      break;
    case 4:
      message.success(i18next.t("You are now on break!"), 1);
      break;
    default:
      message.error(i18next.t("Agent status error!"), 1);
  }

  return {
    type: actionTypes.UPDATE_AGENT_STATUS_DONE,
    agent_status: agent_status
  }
}

export const updateAgentStatusFailed = error => {
  message.error(i18next.t("Error updating your status!"), 1);
  return {
    type: actionTypes.UPDATE_AGENT_STATUS_FAILED,
    agent_status: 0,
    update_agent_status_error: error,
  }
}

// update AgentStatus
export const updateAgentStatus = (status) => {
  return dispatch => {
    dispatch(updateAgentStatusStarted());
    let h_axios = prepAxiosHeaders();
    h_axios.post(window._env_.REACT_APP_API_URL + '/api/agent/status_change/',
      {
        status: status,
      })
      .then(res => {
        if (res.data.status === 'OK') {
          dispatch(updateAgentStatusDone(res.data.data.agent_status_numeric));
        } else {
          dispatch(updateAgentStatusFailed(res.data.status))
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(updateAgentStatusFailed(err))
        console.log("Error loading...");
      });
  }
}



// hangup current call
export const hangupCurrentCall = (subscriber_id) => {
  let h_axios = prepAxiosHeaders();
  h_axios.post(window._env_.REACT_APP_API_URL + '/api/agent/hangup_current_call/',
    {
      subscriber_id: subscriber_id,
    })
    .then(res => {
      console.log(res.data);
    })
    .catch(err => {
      console.log("Error hangupCurrentCall...");
    });
}



// transfer call
// {"transfer_type": "cold_xfer", "transfer_to": "##1"}
export const XferCall = (subscriber_id, transfer_type, transfer_to) => {
  let h_axios = prepAxiosHeaders();
  h_axios.post(window._env_.REACT_APP_API_URL + '/api/agent/xfer_current_call/',
    {
      subscriber_id: subscriber_id,
      transfer_type: transfer_type,
      transfer_to: transfer_to,
    })
    .then(res => {
      console.log(res.data);
    })
    .catch(err => {
      console.log("Error XferCall...");
    });
}
