import React from 'react';
import { Form, Input, Button, Space } from 'antd';
import { EditOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import { connect } from 'react-redux';
import i18next from 'i18next';

// https://github.com/KillerCodeMonkey/ngx-quill/issues/351
// https://www.rockyourcode.com/assertion-failed-input-argument-is-not-an-html-input-element

class NoteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      note_sent: false,
      note_initial_value: '',
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.subscriber_id !== this.props.subscriber_id) {
      this.setState({
        note_sent: false,
        note_initial_value: '',
      });
    }
  }

  handleFormSubmit = (value, postFuncAddNote) => {
    console.log("value.note: ", value.note)
    postFuncAddNote(value.note);
    this.setState({
      note_sent: true,
    });
  }

  handleChange = (event) => {
    this.setState({
      note_initial_value: event.target.value
    });
  };

  onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  render() {
    return (
      <div>
        {
          this.state.note_sent ?
            <Space direction="horizontal" size={5} style={{ margin: 10 }}>
              <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '25px' }} />
              <h4>{i18next.t("Note Added!")}</h4>
            </Space>
            :
            <Space direction="vertical" size={1}>
              <h3>{i18next.t("Add a note")}:</h3>
              <Form
                onFinish={(value) => this.handleFormSubmit(value, this.props.postFuncAddNote)}
                onFinishFailed={this.onFinishFailed}
                initialValues={{ note: this.state.note_initial_value }}
                // layout="inline"
              >
                <Form.Item name="note" style={{ marginBottom: 5 }}>
                  <Input.TextArea
                    placeholder={i18next.t("Enter a note...")}
                    onChange={this.handleChange}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" icon={<EditOutlined />} htmlType="submit">{i18next.t("Add Note")}</Button>
                </Form.Item>
              </Form>
            </Space>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    note_error: state.contact.note_error
  }
}

export default connect(mapStateToProps)(NoteForm);
