import React from 'react';
import { Skeleton } from 'antd';
import Interweave from 'interweave';


class ContactScript extends React.Component {

  render() {
    let agent_script = this.props.contact_data['callcenter_config']['agent_script'];
    agent_script = agent_script.replace(/(\r\n|\n|\r)/g, "<br />");
    return (
      <div>
        {
          this.props.contact_data ?
            <Interweave content={agent_script} />
            :
            <Skeleton active />
        }
      </div>
    )
  }
};

export default ContactScript;
