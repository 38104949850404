import React from 'react';
import { Form, Button, Space } from 'antd';
import { connect } from 'react-redux';
import { SendOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import { cyan } from '@ant-design/colors';
import { prepAxiosHeaders } from '../store/utility';
import { message } from 'antd';
import i18next from 'i18next';


class PushCRMComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pushed: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.subscriber_id !== this.props.subscriber_id) {
      this.setState({
        pushed: false,
      });
    }
  }

  sendPushCRM = () => {
    console.log("**************************** sendPushCRM ***************************")

    let contact_fields = { ...this.props.contact_data.contact_fields }
    console.log(">> subscriber_id: ", contact_fields['subscriber_id']);

    // Sent Post to URL
    let h_axios = prepAxiosHeaders();
    let api_url = '/api/agent/send_crm/';
    let data = {
      "subscriber_id": contact_fields['subscriber_id']
    }
    h_axios.post(window._env_.REACT_APP_API_URL + api_url, data)
      .then(res => {
        this.setState({
          pushed: true
        });
        message.success(i18next.t("Push to CRM - Sent!"), 1);
      })
      .catch(err => {
        this.setState({
          pushed: false
        });
        message.error(i18next.t("Error pushing to CRM URL!"), 1);
        console.log("Error pushing to CRM URL...");
        console.log(err);
      })
  }

  handleFormSubmit = (value) => {
    this.sendPushCRM();
  }

  onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  render() {
    // len_push_to_crm_url is now the length of the url
    console.log("len_push_to_crm_url:", this.props.contact_data.callcenter_config.len_push_to_crm_url);
    let valid_url = this.props.contact_data.callcenter_config.len_push_to_crm_url > 5;
    return (
      < div >
        {
          valid_url &&
          < div >
            {
              this.state.pushed ?
                <Space direction="horizontal" size={5} style={{ margin: 10 }}>
                  <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '25px' }} />
                  <h4>{i18next.t("Push to CRM - Sent!")}</h4>
                </Space>
                :
                <Space direction="vertical" size={1}>
                  <Form
                    onFinish={(value) => this.handleFormSubmit(value)}
                    onFinishFailed={this.onFinishFailed}
                  >
                    <Button
                      onClick={this.showModal}
                      shape="round" style={{ "backgroundColor": cyan[5], "color": "#FFFFFF", "margin": '15px 0px 0px 0px' }}
                      icon={<SendOutlined />} type="primary" size="middle" htmlType="submit">
                      {i18next.t("Push to CRM")}
                    </Button>
                  </Form>
                </Space>
            }
          </div >
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contact_data: state.contact.contact_data
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export const PushCRM = connect(mapStateToProps, mapDispatchToProps)(PushCRMComponent);