import * as actionTypes from '../actions/actionType';
import { updateObject } from '../utility';

// export const LOAD_ARTICLE = 'LOAD_ARTICLE';
// export const LOAD_DONE_ARTICLE = 'LOAD_DONE_ARTICLE';
// export const LOAD_FAILED_ARTICLE = 'LOAD_FAILED_ARTICLE';

const initialState = {
  article_error: null,
  article_loading: false,
  articles: []
}

const loadArticle = (state, action) => {
  return updateObject(state, {
    article_error: null,
    article_loading: true
  });
}

const loadDoneArticle = (state, action) => {
  return updateObject(state, {
    articles: action.articles,
    article_error: null,
    article_loading: false
  });
}

const loadFailedArticle = (state, action) => {
  return updateObject(state, {
    article_error: action.error,
    article_loading: false
  });
}

// -------------------------
// REDUCER
// -------------------------
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_ARTICLE: return loadArticle(state, action);
    case actionTypes.LOAD_DONE_ARTICLE: return loadDoneArticle(state, action);
    case actionTypes.LOAD_FAILED_ARTICLE: return loadFailedArticle(state, action);
    default:
      return state;
  }
};

export default authReducer;