import React from 'react';
import { Tag } from 'antd';
import i18next from 'i18next';


class CallStatusTag extends React.Component {

  status_color = (callstatus) => {
    switch (callstatus) {
      case 'ANSWER':
        return 'green'
      case 'BUSY':
        return 'cyan'
      case 'NOANSWER':
        return 'geekblue'
      case 'FAILED':
        return 'magenta'
      default:
        return 'vocalno'
    }
  };

  translated_color = (callstatus) => {
    switch (callstatus) {
      case 'ANSWER':
        return i18next.t("ANSWER")
      case 'BUSY':
        return i18next.t("BUSY")
      case 'NOANSWER':
        return i18next.t("NOANSWER")
      case 'FAILED':
        return i18next.t("FAILED")
      default:
        return callstatus
    }
  };

  render() {
    return (
      <Tag color={this.status_color(this.props.callstatus)}>
        {this.translated_color(this.props.callstatus)}
      </Tag>
    )
  }
}

export default CallStatusTag;