import axios from 'axios';


//
// Quick update object
//
export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  }
}


function isEmpty(str) {
  return (!str || 0 === str.length);
}

//
// Prepare Axios with Authentication headers
//
export const prepAxiosHeaders = () => {
  // get token
  let token = localStorage.getItem('token');
  if (isEmpty(token)) {
    return false;
  }
  let res = localStorage.getItem('token').split('|');
  let [xusername, xpassword] = [res[0], res[1]];
  // console.log(xusername, xpassword);

  axios.defaults.headers = {
    "Content-Type": "application/json",
    //   Authorization: `Token ${this.props.token}`
  };

  axios.defaults.headers.common = {
    "XAGENT-USERNAME": xusername,
    "XAGENT-PASSWORD": xpassword
  };
  return axios;
}
