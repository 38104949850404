import React from 'react';
import { Popover } from 'antd';
import { ReactSipPhone } from 'react-sip-phone'
import PopoverDialExtension from '../components/PopoverDialExtension';
import { InfoCircleOutlined } from '@ant-design/icons';
import 'react-sip-phone/dist/index.css'
import i18next from 'i18next';


let WEBRTC_HOST = window.location.hostname;
let WEBRTC_USER = localStorage.getItem('phone_login');
let WEBRTC_PASSWORD = localStorage.getItem('phone_password');
let WEBRTC_AUTOANSWER = localStorage.getItem('enable_webrtc_autoanswer') === 'true';

if (window._env_.DEV_TEST_MODE) {
  WEBRTC_HOST = window._env_.DEV_TEST_HOST;
  WEBRTC_USER = window._env_.DEV_TEST_USER;
  WEBRTC_PASSWORD = window._env_.DEV_TEST_PASSWORD;
}

console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
console.log("WebRTC Config: ", WEBRTC_HOST, WEBRTC_USER, WEBRTC_PASSWORD, WEBRTC_AUTOANSWER);
console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");

const sipuri = `${WEBRTC_USER}@${WEBRTC_HOST}`
const websocket = `wss://${WEBRTC_HOST}:7443`
const password = `${WEBRTC_PASSWORD}`

const DIAL_COUNTRY_CODE = window._env_.DIAL_COUNTRY_CODE || '';

console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
console.log("sipuri: ", sipuri);
console.log("websocket: ", websocket);
console.log("password: ", password);
console.log("DIAL_COUNTRY_CODE: ", DIAL_COUNTRY_CODE);
console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");

let auto_answer = WEBRTC_AUTOANSWER === true ? "ON" : "OFF";
const WebPhone = () => {
  return (
    <div className="webphone-container">
      <Popover className="webphone-extensions" placement="topLeft" content={PopoverDialExtension()}>
        <h4>{i18next.t('Extensions')} <InfoCircleOutlined /></h4>
      </Popover>

      <span className="debug-message center-box">Auto-Answer: {auto_answer}</span>

      <div className="webphone-div">
        <ReactSipPhone
          name={i18next.t('WebPhone')}
          width={150}
          height={600}
          sipCredentials={{
            sipuri: sipuri,
            password: password
          }}
          sipConfig={{
            websocket: websocket,
            defaultCountryCode: DIAL_COUNTRY_CODE,
          }}
          phoneConfig={{
            disabledButtons: 'transfer', // Will remove button(s) from Phone component. E.g. hold transfer dialpadopen mute '
            disabledFeatures: '', // Will remove feature(s) from application. E.g. settings remoteid
            defaultDial: '0001',          // (strict-mode only) the default destination. E.g. 1234567890
            sessionsLimit: 1,         // limits amount of sessions user can have active
            attendedTransferLimit: 0, // limits amount of attendedTransfer sessions user can have active
            autoAnswer: WEBRTC_AUTOANSWER   // enable the auto-answer on incoming calls
          }}
          appConfig={{
            mode: '', // 'strict' will activate a simple and limited user experience. set to sessionLimit 1 if using 'strict'
            started: false, // (strict-mode only) keeps track of call button visability during strict-mode
            appSize: 'small' // assign 'large', 'medium' or 'small' to deine the font size & button size
          }}
        />
      </div>
    </div>
  );
}

export default WebPhone;
