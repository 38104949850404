import React from 'react';
import { Tag } from 'antd';
import i18next from 'i18next';

class CallbackLaterStatusTag extends React.Component {

  status_color = (status_name) => {
    switch (status_name) {
      case 'Pending':
        return 'cyan'
      case 'Connected':
        return 'green'
      case 'Not Connected':
        return 'magenta'
      default:
        return 'vocalno'
    }
  };

  translated_color = (status_name) => {
    switch (status_name) {
      case 'Pending':
        return i18next.t("Pending")
      case 'Connected':
        return i18next.t("Connected")
      case 'Not Connected':
        return i18next.t("Not Connected")
      default:
        return status_name
    }
  };

  render() {
    return (
      <Tag color={this.status_color(this.props.status_name)}>
        {this.translated_color(this.props.status_name)}
      </Tag>
    )
  }
}

export default CallbackLaterStatusTag;