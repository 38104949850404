import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

// localStorage.setItem('i18nextLng', 'fr');
// const val_i18nextLng = localStorage.getItem('i18nextLng');
// console.log("val_i18nextLng:", val_i18nextLng)

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "Username": "Username",
      "Please input your username!": "Please input your username!",
      "Please input your password!": "Please input your password!",
      "Password": "Password",
      "Log in": "Log in",
      "Login": "Login",
      "Home": "Home",
      "Callback Later": "Callback Later",
      "Last calls": "Last calls",
      "Logout": "Logout",
      // Webphone
      "Extensions": "Extensions",
      "WebPhone": "WebPhone",
      // LastCallView
      "Campaign": "Campaign",
      "Phone number": "Phone number",
      "Contact Name": "Contact Name",
      "Date": "Date",
      "Duration": "Duration",
      "CallStatus": "CallStatus",
      "Disposition": "Disposition",
      // ContactView
      "Script": "Script",
      "History": "History",
      "Notes": "Notes",
      "External CRM": "External CRM",
      "Open CRM in a new window": "Open CRM in a new window",
      "No call detected!": "No call detected!",
      // CallbackLaterView
      "Callback date": "Callback date",
      "Status": "Status",
      "Action": "Action",
      "Search": "Search",
      "Reset": "Reset",
      "Callback": "Callback",
      "Filter": "Filter",
      // XferButton
      "Cold Xfer requested!": "Cold Xfer requested!",
      "Warm Xfer requested!": "Warm Xfer requested!",
      "3Way Xfer requested!": "3Way Xfer requested!",
      "Call Transfer": "Call Transfer",
      "Xfer": "Xfer",
      "Transfer": "Transfer",
      "Cold": "Cold",
      "Warm": "Warm",
      "3Way": "3Way",
      "Select to whom to transfer": "Select to whom to transfer",
      "Transfer type": "Transfer type",
      "Transfer to": "Transfer to",
      "Cancel": "Cancel",
      // Wrap Up
      "Wrap Up time completed, get ready to receive more calls!": "Wrap Up time completed, get ready to receive more calls!",
      "Wrap Up": "Wrap Up",
      // PopoverDialExtension
      "Extension": "Extension",
      "Description": "Description",
      "Log out of the queue to stop receiving calls.": "Log out of the queue to stop receiving calls.",
      "Log in to the queue to manually answer calls.": "Log in to the queue to manually answer calls.",
      "Standby: Call into the queue to automatically answer calls instantly while listening to Music on Hold.": "Standby: Call into the queue to automatically answer calls instantly while listening to Music on Hold.",
      "Standby-Silence: As 1002, automatically answering calls instantly with no Music on Hold.": "Standby-Silence: As 1002, automatically answering calls instantly with no Music on Hold.",
      "Echo Test: Test and confirm 2 way audio via microphone and headset.": "Echo Test: Test and confirm 2 way audio via microphone and headset.",
      "Milliwatt Test Tone: Continuous test tone to confirm audio quality.": "Milliwatt Test Tone: Continuous test tone to confirm audio quality.",
      "Extensions available": "Extensions available",
      // NoteForm
      "Note Added!": "Note Added!",
      "Add a note": "Add a note",
      "Enter a note...": "Enter a note...",
      "Add Note": "Add Note",
      // HangupButton
      "Hangup": "Hangup",
      "Hangup signal sent!": "Hangup signal sent!",
      // FormDialExtension
      "Dial an extension": "Dial an extension",
      "1000: Log out": "1000: Log out",
      "1001: Available": "1001: Available",
      "1002: Standby": "1002: Standby",
      "1004: Standby (Quiet)": "1004: Standby (Quiet)",
      "0001: Echo Test": "0001: Echo Test",
      "0002: Tone Test": "0002: Tone Test",
      // DNCButton
      "Added To DNC": "Added To DNC",
      "Add To DNC": "Add To DNC",
      // ContactInfo
      "Update Disposition": "Update Disposition",
      // ContactDetails
      "Search on Google": "Search on Google",
      "Contact Info": "Contact Info",
      "Full name": "Full name",
      "Contact Number": "Contact Number",
      "Last Called": "Last Called",
      // CallStatusTag
      "ANSWER": "ANSWER",
      "BUSY": "BUSY",
      "NOANSWER": "NOANSWER",
      "FAILED": "FAILED",
      // CallbackLaterStatusTag
      "Pending": "Pending",
      "Connected": "Connected",
      "Not Connected": "Not Connected",
      // Callback Later
      "Edit callback later": "Edit callback later",
      "Submit": "Submit",
      "Current callback date": "Current callback date",
      "Callback added!": "Callback added!",
      "Callback later": "Callback later",
      "Add callback later": "Add callback later",
      "Edit": "Edit",
      // Agent Status
      "Please connect your SIP phone!": "Please connect your SIP phone!",
      "Registered": "Registered",
      "Not registered": "Not registered",
      "Set status": "Set status",
      "Available": "Available",
      "On Break": "On Break",
      "Log Out": "Log Out",
      "Unknown": "Unknown",
      "Logged Out": "Logged Out",
      "On Standby": "On Standby",
      // Agent
      "You are now logged out!": "You are now logged out!",
      "You are now available!": "You are now available!",
      "You are now available on demand!": "You are now available on demand!",
      "You are now on break!": "You are now on break!",
      "Agent status error!": "Agent status error!",
      "Error updating your status!": "Error updating your status!",
      // Contact
      "Disposition Updated!": "Disposition Updated!",
      "Error updating disposition!": "Error updating disposition!",
      "DNC Updated!": "DNC Updated!",
      "Error updating DNC!": "Error updating DNC!",
      "Note has been written!": "Note has been written!",
      "Error writting the note!": "Error writting the note!",
      "Callback has been created!": "Callback has been created!",
      "Error creating the callback!": "Error creating the callback!",
      "Callback has been updated!": "Callback has been updated!",
      "Error updating the callback!": "Error updating the callback!",
      // Contact Reducer
      "New contact data loaded!": "New contact data loaded!",
      "Contact data loaded!": "Contact data loaded!",
      "Hurry up and fill in the contact data!": "Hurry up and fill in the contact data!",
      // AngetReportView
      "Agent report": "Agent report",
      "ValueCalls": "{{value}} calls",
      "Rate": "Rate",
      "Answered call": "Answered call",
      "Unanswered call": "Unanswered call",
      "Total call": "Total call",
      "calls": "calls",
      "Total talk time": "Total talk time",
      "Avg. talk time": "Avg. talk time",
      "Max talk time": "Max talk time",
      "Answer Ratio": "Answer Ratio",
      "Answered / Unanswered": "Answered / Unanswered",
      "min": "min",
      // Soundboard
      "Soundboard": "Soundboard",
      "Audio": "Audio",
      "Preview": "Preview",
      "Play to Contact": "Play to Contact",
      "Audio played to the contact!": "Audio played to the contact!",
      "Error playing audio to contact!": "Error playing audio to contact!",
      "Stop Audio": "Stop Audio",
      "Audio stopped!": "Audio stopped!",
      // PushCRM
      "Push to CRM - Sent!": "Push to CRM - Sent!",
      "Error pushing to CRM URL!": "Error pushing to CRM URL!",
      "Push to CRM": "Push to CRM",
    }
  },
  fr: {
    translation: {
      "Username": "Nom d'utilisateur",
      "Please input your username!": "Veuillez saisir votre nom d'utilisateur!",
      "Please input your password!": "Veuillez saisir votre mot de passe!",
      "Password": "Mot de passe",
      "Log in": "Identification",
      "Login": "S'identifier",
      "Home": "Accueil",
      "Callback Later": "Rappels",
      "Last calls": "Derniers appels",
      "Logout": "Déconnexion",
      // Webphone
      "Extensions": "Extensions",
      "WebPhone": "WebPhone",
      // LastCallView
      "Campaign": "Campagne",
      "Phone number": "Numéro de téléphone",
      "Contact Name": "Nom du contact",
      "Date": "Date",
      "Duration": "Durée",
      "CallStatus": "État de l'appel",
      "Disposition": "Disposition",
      // ContactView
      "Script": "Scripte",
      "History": "Histoire",
      "Notes": "Remarques",
      "External CRM": "CRM externe",
      "Open CRM in a new window": "Ouvrir le CRM dans une nouvelle fenêtre",
      "No call detected!": "Aucun appel détecté!",
      // CallbackLaterView
      "Callback date": "Date de rappel",
      "Status": "Status",
      "Action": "Action",
      "Search": "Rechercher",
      "Reset": "Réinitialiser",
      "Callback": "Rappel",
      "Filter": "Filtrer",
      // XferButton
      "Cold Xfer requested!": "Cold Xfer a demandé!",
      "Warm Xfer requested!": "Warm Xfer demandé!",
      "3Way Xfer requested!": "3Way Xfer a demandé!",
      "Call Transfer": "Transfert d'appel",
      "Xfer": "Xfer",
      "Transfer": "Transfert",
      "Cold": "Froid",
      "Warm": "Chaud",
      "3Way": "3Way",
      "Select to whom to transfer": "Sélectionnez à qui transférer",
      "Transfer type": "Type de transfert",
      "Transfer to": "Transférer à",
      "Cancel": "Annuler",
      // Wrap Up
      "Wrap Up time completed, get ready to receive more calls!": "Récapitulatif terminé, préparez-vous à recevoir plus d'appels!",
      "Wrap Up": "Récapitulatif",
      // PopoverDialExtension
      "Extension": "Extension",
      "Description": "Description",
      "Log out of the queue to stop receiving calls.": "Déconnectez-vous de la file d'attente pour ne plus recevoir d'appels.",
      "Log in to the queue to manually answer calls.": "Connectez-vous à la file d'attente pour répondre manuellement aux appels.",
      "Standby: Call into the queue to automatically answer calls instantly while listening to Music on Hold.": "Veille: Appelez dans la file d'attente pour répondre automatiquement aux appels tout en écoutant la musique d'attente.",
      "Standby-Silence: As 1002, automatically answering calls instantly with no Music on Hold.": "Veille-Silence: Comme 1002, répond automatiquement aux appels sans musique d'attente.",
      "Echo Test: Test and confirm 2 way audio via microphone and headset.": "Test d'écho",
      "Milliwatt Test Tone: Continuous test tone to confirm audio quality.": "Test de tonalité Milliwatt",
      "Extensions available": "Extensions disponibles",
      // NoteForm
      "Note Added!": "Note ajoutée!",
      "Add a note": "Ajouter une note",
      "Enter a note...": "Entrez une note ...",
      "Add Note": "Ajouter une note",
      // HangupButton
      "Hangup": "Hangup",
      "Hangup signal sent!": "Signal de raccrochage envoyé!",
      // FormDialExtension
      "Dial an extension": "Composer une extension",
      "1000: Log out": "1000: Déconnexion",
      "1001: Available": "1001: Disponible",
      "1002: Standby": "1002: Veille",
      "1004: Standby (Quiet)": "1004: Veille (silencieux)",
      "0001: Echo Test": "0001: Test d'écho",
      "0002: Tone Test": "0002: Test de tonalité",
      // DNCButton
      "Added To DNC": "Ajouté à DNC",
      "Add To DNC": "Ajouter à DNC",
      // ContactInfo
      "Update Disposition": "Mettre à jour la disposition",
      // ContactDetails
      "Search on Google": "Rechercher sur Google",
      "Contact Info": "Informations du contact",
      "Full name": "Nom complet",
      "Contact Number": "Numéro de contact",
      "Last Called": "Dernier Appelé",
      // CallStatusTag
      "ANSWER": "RÉPONDU",
      "BUSY": "OCCUPÉ",
      "NOANSWER": "PAS RÉPONDU",
      "FAILED": "ÉCHOUÉ",
      // CallbackLaterStatusTag
      "Pending": "En attente",
      "Connected": "Connecté",
      "Not Connected": "Non Connecté",
      // Callback Later
      "Edit callback later": "Modifier le rappel",
      "Submit": "Soumettre",
      "Current callback date": "Date de rappel actuelle",
      "Callback added!": "Rappel ajouté!",
      "Callback later": "Rappel",
      "Add callback later": "Ajouter un rappel",
      "Edit": "Modifier",
      // Agent Status
      "Please connect your SIP phone!": "Veuillez connecter votre téléphone SIP!",
      "Registered": "Connecté",
      "Not registered": "Pas Connecté",
      "Set status": "Définir l'état",
      "Available": "Disponible",
      "On Break": "En Pause",
      "Log Out": "Se Déconnecter",
      "Unknown": "Inconnu",
      "Logged Out": "Déconnecté",
      "On Standby": "En attente",
      // Agent.js
      "You are now logged out!": "Vous êtes maintenant déconnecté!",
      "You are now available!": "Vous êtes maintenant disponible!",
      "You are now available on demand!": "Vous êtes désormais disponible sur demande!",
      "You are now on break!": "Vous êtes maintenant en pause!",
      "Agent status error!": "Erreur d'état de l'agent!",
      "Error updating your status!": "Erreur lors de la mise à jour de votre statut",
      // Contact
      "Disposition Updated!": "Disposition mise à jour!",
      "Error updating disposition!": "Erreur lors de la mise à jour de la disposition",
      "DNC Updated!": "DNC mise à jour!",
      "Error updating DNC!": "Erreur lors de la mise à jour de DNC!",
      "Note has been written!": "Votre note a été écrite!",
      "Error writting the note!": "Erreur lors de l'écriture de la note!",
      "Callback has been created!": "Le rappel a été créé!",
      "Error creating the callback!": "Erreur lors de la création du rappel!",
      "Callback has been updated!": "Le rappel a été mis à jour!",
      "Error updating the callback!": "Erreur lors de la mise à jour du rappel!",
      // Contact Reducer
      "New contact data loaded!": "Données du contact chargées!",
      "Contact data loaded!": "Données de contact chargées!",
      "Hurry up and fill in the contact data!": "Dépêchez-vous et remplissez les données de contact!",
      // AngetReportView
      "Agent report": "Rapport d'agent",
      "ValueCalls": "{{value}} appels",
      "Rate": "Taux",
      "Answered call": "Appel répondu",
      "Unanswered call": "Appel sans réponse",
      "Total call": "Appel total",
      "calls": "appels",
      "Total talk time": "Temps de conversation total",
      "Avg. talk time": "Temps de conversation moyen",
      "Max talk time": "Temps de conversation maximal",
      "Answer Ratio": "Ratio de réponse",
      "Answered / Unanswered": "Répondu / Sans réponse",
      "min": "min",
      // Soundboard
      "Soundboard": "Soundboard",
      "Audio": "Audio",
      "Preview": "Aperçu",
      "Play to Contact": "Jouer au contact",
      "Audio played to the contact!": "L'audio joué au contact!",
      "Error playing audio to contact!": "Erreur lors de la lecture de l'audio au contact!",
      "Stop Audio": "Arrêter l'audio",
      "Audio stopped!": "Audio arrêté!",
      // PushCRM
      "Push to CRM - Sent!": "Push to CRM - Envoyé!",
      "Error pushing to CRM URL!": "Erreur lors du transfert vers l'URL du CRM!",
      "Push to CRM": "Pousser vers le CRM",
    }
  },
  es: {
    translation: {
      "Username": "Nombre de usuario",
      "Please input your username!": "¡Ingrese su nombre de usuario!",
      "Please input your password!": "¡Ingrese su contraseña!",
      "Password": "Contraseña",
      "Log in": "Acceder",
      "Login": "Iniciar sesión",
      "Home": "Inicio",
      "Callback Later": "Devolución de llamada",
      "Last calls": "Últimas llamadas",
      "Logout": "Salir",
      // Webphone
      "Extensions": "Extensiones",
      "WebPhone": "WebPhone",
      // LastCallView
      "Campaign": "Campana",
      "Phone number": "Número de teléfono",
      "Contact Name": "Nombre del contacto",
      "Date": "Fecha",
      "Duration": "Duración",
      "CallStatus": "Estado de la llamada",
      "Disposition": "Disposición",
      // ContactView
      "Script": "Guión",
      "History": "Historial",
      "Notes": "Notas",
      "External CRM": "CRM externo",
      "Open CRM in a new window": "Abrir CRM en una nueva ventana",
      "No call detected!": "¡No se detectó ninguna llamada!",
      // CallbackLaterView
      "Callback date": "Fecha de devolución",
      "Status": "Estado",
      "Action": "Acción",
      "Search": "Buscar",
      "Reset": "Reiniciar",
      "Callback": "Llamar de vuelta",
      "Filter": "Filtrar",
      // XferButton
      "Cold Xfer requested!": "¡Cold Xfer solicitado!",
      "Warm Xfer requested!": "¡Warm Xfer solicitado!",
      "3Way Xfer requested!": "¡3Way Xfer solicitado!",
      "Call Transfer": "Transferencia de llamada",
      "Xfer": "Transferir",
      "Transfer": "Transferir",
      "Cold": "Frío",
      "Warm": "Cálido",
      "3Way": "3Way",
      "Select to whom to transfer": "Seleccionar a quién transferir",
      "Transfer type": "Tipo de transferencia",
      "Transfer to": "Transferir a",
      "Cancel": "Cancelar",
      // Wrap Up
      "Wrap Up time completed, get ready to receive more calls!": "Tiempo de cierre completado, ¡prepárese para recibir más llamadas!",
      "Wrap Up": "Cierre",
      // PopoverDialExtension
      "Extension": "Extensión",
      "Description": "Descripción",
      "Log out of the queue to stop receiving calls.": "Salir de la cola para dejar de recibir llamadas",
      "Log in to the queue to manually answer calls.": "Entrar en la cola para responder llamadas manualmente.",
      "Standby: Call into the queue to automatically answer calls instantly while listening to Music on Hold.": "En espera: Entrar a la cola para responder automáticamente las llamadas al instante mientras escucha música de espera",
      "Standby-Silence: As 1002, automatically answering calls instantly with no Music on Hold.": "Standby-Silencio: Como 1002, contesta automáticamente llamadas instantáneamente pero sin música en espera",
      "Echo Test: Test and confirm 2 way audio via microphone and headset.": "Prueba de eco: prueba y confirma el audio bidireccional mediante micrófono y auriculares.",
      "Milliwatt Test Tone: Continuous test tone to confirm audio quality.": "Tono de prueba de milivatios: tono de prueba continuo para confirmar la calidad del audio",
      "Extensions available": "Extensiones disponibles",
      // NoteForm
      "Note Added!": "¡Nota agregada!",
      "Add a note": "Agregar una nota",
      "Enter a note...": "Enter a note ...",
      "Add Note": "Agregar nota",
      // HangupButton
      "Hangup": "Hangup",
      "Hangup signal sent!": "¡Señal de colgar enviada!",
      // FormDialExtension
      "Dial an extension": "Marcar una extensión",
      "1000: Log out": "1000: Cerrar sesión",
      "1001: Available": "1001: disponible",
      "1002: Standby": "1002: En espera",
      "1004: Standby (Quiet)": "1004: En espera (silencioso)",
      "0001: Echo Test": "0001: Prueba de eco",
      "0002: Tone Test": "0002: Prueba de tono",
      // DNCButton
      "Added To DNC": "Agregado a DNC",
      "Add To DNC": "Agregar a DNC",
      // ContactInfo
      "Update Disposition": "Actualizar disposición",
      // ContactDetails
      "Search on Google": "Buscar en Google",
      "Contact Info": "Información de contacto",
      "Full name": "Nombre completo",
      "Contact Number": "Número de contacto",
      "Last Called": "Última llamada",
      // CallStatusTag
      "ANSWER": "RESPONDIDA",
      "BUSY": "OCCUPADO",
      "NOANSWER": "SIN RESPUESTA",
      "FAILED": "FALLADO",
      // CallbackLaterStatusTag
      "Pending": "Pendiente",
      "Connected": "Conectado",
      "Not Connected": "No conectado",
      // Callback Later
      "Edit callback later": "Editar devolución de llamada más tarde",
      "Submit": "Enviar",
      "Current callback date": "Fecha de devolución de llamada actual",
      "Callback added!": "¡Devolución de llamada agregada!",
      "Callback later": "Devolución de llamada más tarde",
      "Add callback later": "Agregar devolución de llamada más tarde",
      "Edit": "Editar",
      // Agent Status
      "Please connect your SIP phone!": "¡Conecte su teléfono SIP!",
      "Registered": "Registrado",
      "Not registered": "No registrado",
      "Set status": "Establecer estado",
      "Available": "Disponible",
      "On Break": "En Descanso",
      "Log Out": "Desconectar",
      "Unknown": "Desconocido",
      "Logged Out": "Desconectado",
      "On Standby": "En Espera",
      // Agent.js
      "You are now logged out!": "¡Ahora estás desconectado!",
      "You are now available!": "¡Ya estás disponible!",
      "You are now available on demand!": "¡Ahora está disponible bajo demanda!",
      "You are now on break!": "¡Ahora estás de descanso!",
      "Agent status error!": "Error de estado del agente",
      "Error updating your status!": "¡Error al actualizar su estado!",
      // Contact
      "Disposition Updated!": "¡Disposición actualizado!",
      "Error updating disposition!": "¡Error al actualizar la disposición!",
      "DNC Updated!": "DNC actualizado!",
      "Error updating DNC!": "¡Error al actualizar DNC!",
      "Note has been written!": "¡Se ha escrito una nota!",
      "Error writting the note!": "¡Error al escribir la nota!",
      "Callback has been created!": "¡Se ha creado la devolución de llamada!",
      "Error creating the callback!": "¡Error al crear la devolución de llamada!",
      "Callback has been updated!": "¡Se actualizó la devolución de llamada!",
      "Error updating the callback!": "¡Error al actualizar la devolución de llamada!",
      // Contact Reducer
      "New contact data loaded!": "¡Nuevos datos de contacto cargados!",
      "Contact data loaded!": "¡Datos de contacto cargados!",
      "Hurry up and fill in the contact data!": "¡Date prisa y completa los datos de contacto!",
      // AngetReportView
      "Agent report": "Informe de agente",
      "ValueCalls": "{{value}} llama",
      "Rate": "Ratio",
      "Answered call": "Llamada respondida",
      "Unanswered call": "Llamada sin respuesta",
      "Total call": "Llamada total",
      "calls": "llamadas",
      "Total talk time": "Tiempo total de conversación",
      "Avg. talk time": "Tiempo medio de conversación",
      "Max talk time": "Tiempo máximo de conversación",
      "Answer Ratio": "Proporción de respuestas",
      "Answered / Unanswered": "Respondido / No respondido",
      "min": "min",
      // Soundboard
      "Soundboard": "Consola",
      "Audio": "Audio",
      "Preview": "Vista previa",
      "Play to Contact": "Jugar al contacto",
      "Audio played to the contact!": "¡Audio reproducido al contacto!",
      "Error playing audio to contact!": "¡Error al reproducir audio al contacto!",
      "Stop Audio": "Parar el audio",
      "Audio stopped!": "¡Audio parado! ",
      // PushCRM
      "Push to CRM - Sent!": "Push to CRM - ¡Enviado!",
      "Error pushing to CRM URL!": "Error d'envio a la URL de CRM.",
      "Push to CRM": "Empuje a CRM",
    }
  },
  pt: {
    translation: {
      "Username": "Nome do usuário",
      "Please input your username!": "Por favor, insira seu nome de usuário!",
      "Please input your password!": "Por favor, insira sua senha!",
      "Password": "Senha",
      "Log in": "Conecte-se",
      "Login": "Conecte-se",
      "Home": "Início",
      "Callback Later": "Ligue de volta mais tarde",
      "Last calls": "Últimas chamadas",
      "Logout": "Sair",
      // Webphone
      "Extensions": "Extensões",
      "WebPhone": "WebPhone",
      // LastCallView
      "Campaign": "Campanha",
      "Phone number": "Número de telefone",
      "Contact Name": "Nome de contato",
      "Date": "Data",
      "Duration": "Duração",
      "CallStatus": "Status da chamada",
      "Disposition": "Disposição",
      // ContactView
      "Script": "Roteiro",
      "History": "História",
      "Notes": "Notas",
      "External CRM": "CRM Externo",
      "Open CRM in a new window": "Abra o CRM em uma nova janela",
      "No call detected!": "Nenhuma chamada detectada!",
      // CallbackLaterView
      "Callback date": "Data de retorno",
      "Status": "Status",
      "Action": "Açao",
      "Search": "Pesquisa",
      "Reset": "Redefinir",
      "Callback": "Ligue de volta",
      "Filter": "Filtro",
      // XferButton
      "Cold Xfer requested!": "Frio transferir solicitado!",
      "Warm Xfer requested!": "Caloroso transferir solicitado!",
      "3Way Xfer requested!": "3Way transferir solicitado!",
      "Call Transfer": "Transferência de chamadas",
      "Xfer": "Transferir",
      "Transfer": "Transferir",
      "Cold": "Frio",
      "Warm": "Caloroso",
      "3Way": "3Way",
      "Select to whom to transfer": "Selecione para quem transferir",
      "Transfer type": "Tipo de transferência",
      "Transfer to": "Transferir para",
      "Cancel": "Cancelar",
      // Wrap Up
      "Wrap Up time completed, get ready to receive more calls!": "Tempo de encerramento concluído, prepare-se para receber mais ligações!",
      "Wrap Up": "Embrulhar",
      // PopoverDialExtension
      "Extension": "Extensão",
      "Description": "Descrição",
      "Log out of the queue to stop receiving calls.": "Saia da fila para parar de receber chamadas.",
      "Log in to the queue to manually answer calls.": "Faça login na fila para atender chamadas manualmente.",
      "Standby: Call into the queue to automatically answer calls instantly while listening to Music on Hold.": "Em espera: Ligue para a fila para atender chamadas automaticamente instantaneamente enquanto ouve música em espera.",
      "Standby-Silence: As 1002, automatically answering calls instantly with no Music on Hold.": "Em espera Silêncio: Como 1002, atendimento automático de chamadas instantaneamente sem música em espera.",
      "Echo Test: Test and confirm 2 way audio via microphone and headset.": "Teste de eco: teste e confirme o áudio bidirecional via microfone e fone de ouvido.",
      "Milliwatt Test Tone: Continuous test tone to confirm audio quality.": "Tom de teste de miliwatt: tom de teste contínuo para confirmar a qualidade do áudio.",
      "Extensions available": "Extensões disponíveis",
      // NoteForm
      "Note Added!": "Nota adicionada!",
      "Add a note": "Adicione uma anotação",
      "Enter a note...": "Insira uma nota...",
      "Add Note": "Adicionar nota",
      // HangupButton
      "Hangup": "Desligar",
      "Hangup signal sent!": "Sinal Hangup enviado!",
      // FormDialExtension
      "Dial an extension": "Marcar um ramal",
      "1000: Log out": "1000: Sair",
      "1001: Available": "1001: Disponível",
      "1002: Standby": "1002: Em espera",
      "1004: Standby (Quiet)": "1004: Em espera (silencioso)",
      "0001: Echo Test": "0001: Teste de eco",
      "0002: Tone Test": "0002: Teste de Tom",
      // DNCButton
      "Added To DNC": "Adicionado ao DNC",
      "Add To DNC": "Adicionar ao DNC",
      // ContactInfo
      "Update Disposition": "Atualizar disposição",
      // ContactDetails
      "Search on Google": "Pesquisa no Google",
      "Contact Info": "Informações de contato",
      "Full name": "Nome completo",
      "Contact Number": "Número de contato",
      "Last Called": "Última chamada",
      // CallStatusTag
      "ANSWER": "RESPONDA",
      "BUSY": "OCUPADO",
      "NOANSWER": "NOANSWER",
      "FAILED": "FALHOU",
      // CallbackLaterStatusTag
      "Pending": "Pendente",
      "Connected": "Conectado",
      "Not Connected": "Não conectado",
      // Callback Later
      "Edit callback later": "Editar retorno de chamada mais tarde",
      "Submit": "Enviar",
      "Current callback date": "Data de retorno atual",
      "Callback added!": "Retorno de chamada adicionado!",
      "Callback later": "Ligue de volta mais tarde",
      "Add callback later": "Adicionar retorno de chamada mais tarde",
      "Edit": "Editar",
      // Agent Status
      "Please connect your SIP phone!": "Por favor, conecte seu telefone SIP!",
      "Registered": "Registrado",
      "Not registered": "Não registrado",
      "Set status": "Definir status",
      "Available": "Disponível",
      "On Break": "Na ruptura",
      "Log Out": "Sair",
      "Unknown": "Desconhecido",
      "Logged Out": "Desconectado",
      "On Standby": "Em espera",
      // Agent
      "You are now logged out!": "Você está desconectado agora!",
      "You are now available!": "Você já está disponível!",
      "You are now available on demand!": "Agora você está disponível sob demanda!",
      "You are now on break!": "Agora você está de ruptura!",
      "Agent status error!": "Erro de status do agente!",
      "Error updating your status!": "Erro ao atualizar seu status!",
      // Contact
      "Disposition Updated!": "Disposição atualizada!",
      "Error updating disposition!": "Erro ao atualizar a disposição!",
      "DNC Updated!": "DNC atualizado!",
      "Error updating DNC!": "Erro ao atualizar DNC!",
      "Note has been written!": "Nota foi escrita!",
      "Error writting the note!": "Erro ao escrever a nota!",
      "Callback has been created!": "Callback foi criado!",
      "Error creating the callback!": "Erro ao criar callback!",
      "Callback has been updated!": "Callback foi atualizado!",
      "Error updating the callback!": "Erro ao atualizar o retorno de chamada!",
      // Contact Reducer
      "New contact data loaded!": "Novos dados de contato carregados!",
      "Contact data loaded!": "Dados de contato carregados!",
      "Hurry up and fill in the contact data!": "Apresse-se e preencha os dados de contato!",
      // AngetReportView
      "Agent report": "Relatório do agente",
      "ValueCalls": "{{value}} chamadas",
      "Rate": "Razão",
      "Answered call": "Chamada atendida",
      "Unanswered call": "Chamada não atendida",
      "Total call": "Chamada total",
      "calls": "chamadas",
      "Total talk time": "Tempo total de conversação",
      "Avg. talk time": "Tempo médio de conversação",
      "Max talk time": "Tempo máximo de conversação",
      "Answer Ratio": "Razão de resposta",
      "Answered / Unanswered": "Respondido / Não Respondido",
      "min": "min",
      // Soundboard
      "Soundboard": "Mesa de som",
      "Audio": "Áudio",
      "Preview": "Pré-visualizar",
      "Play to Contact": "Jogar para contato",
      "Audio played to the contact!": "Áudio reproduzido para o contato!",
      "Error playing audio to contact!": "Erro ao reproduzir áudio para o contato!",
      "Stop Audio": "Parar Áudio",
      "Audio stopped!": "Áudio parado!",
      // PushCRM
      "Push to CRM - Sent!": "Push to CRM - Enviado!",
      "Error pushing to CRM URL!": "Erro ao enviar para URL do CRM!",
      "Push to CRM": "Enviar para CRM",
    }
  },
  ca: {
    translation: {
      "Username": "Nom d'usuari",
      "Please input your username!": "Introduïu el vostre nom d'usuari!",
      "Please input your password!": "Introduïu la vostra contrasenya!",
      "Password": "Contrasenya",
      "Log in": "Iniciar Sessió",
      "Login": "Iniciar Sessió",
      "Home": "Inici",
      "Callback Later": "Tornar trucada més tard",
      "Last calls": "Últimes trucades",
      "Logout": "Tancar sessió",
      // Webphone
      "Extensions": "Extensions",
      "WebPhone": "WebPhone",
      // LastCallView
      "Campaign": "Campanya",
      "Phone number": "Número de telèfon",
      "Contact Name": "Nom de contacte",
      "Date": "Data",
      "Duration": "Durada",
      "CallStatus": "Estat de la trucada",
      "Disposition": "Disposició",
      // ContactView
      "Script": "Guió",
      "History": "Història",
      "Notes": "Notes",
      "External CRM": "CRM extern",
      "Open CRM in a new window": "Obre CRM en una finestra nova",
      "No call detected!": "No s'ha detectat cap trucada",
      // CallbackLaterView
      "Callback date": "Data de tornar trucada",
      "Status": "Estat",
      "Action": "Acció",
      "Search": "Cerca",
      "Reset": "Restableix",
      "Callback": "Tornar trucada",
      "Filter": "Filtre",
      // XferButton
      "Cold Xfer requested!": "S'ha sol·licitat fred transferència!",
      "Warm Xfer requested!": "S'ha sol·licitat transferència calent!",
      "3Way Xfer requested!": "S'ha sol·licitat transferència 3Way!",
      "Call Transfer": "Transferència de trucades",
      "Xfer": "Transferència",
      "Transfer": "Transferència",
      "Cold": "Fred",
      "Warm": "Calent",
      "3Way": "3Way",
      "Select to whom to transfer": "Selecciona a qui vols transferir",
      "Transfer type": "Tipus de transferència",
      "Transfer to": "Transferir-ho a",
      "Cancel": "Cancel·lar",
      // Wrap Up
      "Wrap Up time completed, get ready to receive more calls!": "S'ha completat el temps de finalització, prepareu-vos per rebre més trucades!",
      "Wrap Up": "Embolicar",
      // PopoverDialExtension
      "Extension": "Extensió",
      "Description": "Descripció",
      "Log out of the queue to stop receiving calls.": "Tanqueu la sessió a la cua per deixar de rebre trucades.",
      "Log in to the queue to manually answer calls.": "Inicieu sessió a la cua per respondre les trucades manualment.",
      "Standby: Call into the queue to automatically answer calls instantly while listening to Music on Hold.": "En espera: Truca a la cua per respondre automàticament a les trucades a l'instant mentre escoltes música en espera.",
      "Standby-Silence: As 1002, automatically answering calls instantly with no Music on Hold.": "Silenci en espera: Com a 1002, respon automàticament a les trucades a l'instant sense cap música en espera.",
      "Echo Test: Test and confirm 2 way audio via microphone and headset.": "Prova de ressò: proveu i confirmeu l'àudio bidireccional mitjançant micròfon i auriculars.",
      "Milliwatt Test Tone: Continuous test tone to confirm audio quality.": "Milliwatt Test Tone: to de prova continu per confirmar la qualitat de l'àudio.",
      "Extensions available": "Extensions disponibles",
      // NoteForm
      "Note Added!": "Nota afegida!",
      "Add a note": "Afegeix una nota",
      "Enter a note...": "Introduïu una nota...",
      "Add Note": "Afegir nota",
      // HangupButton
      "Hangup": "Penjar",
      "Hangup signal sent!": "S'ha enviat el senyal de suspensió!",
      // FormDialExtension
      "Dial an extension": "Marqueu una extensió",
      "1000: Log out": "1000: Tanca la sessió",
      "1001: Available": "1001: disponible",
      "1002: Standby": "1002: espera",
      "1004: Standby (Quiet)": "1004: espera (tranquil)",
      "0001: Echo Test": "0001: prova de ressò",
      "0002: Tone Test": "0002: prova de to",
      // DNCButton
      "Added To DNC": "Afegit a DNC",
      "Add To DNC": "Afegeix a DNC",
      // ContactInfo
      "Update Disposition": "Actualitza la disposició",
      // ContactDetails
      "Search on Google": "Cerca a Google",
      "Contact Info": "Dades de contacte",
      "Full name": "Nom i cognoms",
      "Contact Number": "Número de contacte",
      "Last Called": "Última trucada",
      // CallStatusTag
      "ANSWER": "RESPOSTA",
      "BUSY": "OCUPADA",
      "NOANSWER": "SENSE RESPOSTA",
      "FAILED": "FRACASSAT",
      // CallbackLaterStatusTag
      "Pending": "Pendents",
      "Connected": "Connectat",
      "Not Connected": "No connectat",
      // Callback Later
      "Edit callback later": "Edita la devolució de trucada",
      "Submit": "Sotmetre",
      "Current callback date": "Data actual de la trucada",
      "Callback added!": "S'ha afegit la devolució de trucada!",
      "Callback later": "Devolució de trucada més tard",
      "Add callback later": "Afegeix una trucada més tard",
      "Edit": "Edita",
      // Agent Status
      "Please connect your SIP phone!": "Connecteu el vostre telèfon SIP!",
      "Registered": "Enregistrat",
      "Not registered": "No registrat",
      "Set status": "Estableix l'estat",
      "Available": "Disponible",
      "On Break": "Al descans",
      "Log Out": "Tancar sessió",
      "Unknown": "Desconegut",
      "Logged Out": "Desconnectat",
      "On Standby": "En espera",
      // Agent
      "You are now logged out!": "Ja heu tancat la sessió!",
      "You are now available!": "Ja està disponible!",
      "You are now available on demand!": "Ja està disponible a la carta!",
      "You are now on break!": "Ara esteu al descans!",
      "Agent status error!": "Error d'estat de l'agent!",
      "Error updating your status!": "Error en actualitzar el vostre estat!",
      // Contact
      "Disposition Updated!": "Disposició actualitzada!",
      "Error updating disposition!": "Error en actualitzar la disposició!",
      "DNC Updated!": "DNC actualitzat!",
      "Error updating DNC!": "Error en actualitzar DNC!",
      "Note has been written!": "S'ha escrit una nota!",
      "Error writting the note!": "Error en escriure la nota!",
      "Callback has been created!": "S'ha creat la devolució de trucada!",
      "Error creating the callback!": "Error en crear la devolució de trucada!",
      "Callback has been updated!": "S'ha actualitzat la devolució de trucada!",
      "Error updating the callback!": "Error en actualitzar la devolució de trucada!",
      // Contact Reducer
      "New contact data loaded!": "S'han carregat les dades de contacte noves!",
      "Contact data loaded!": "Les dades de contacte s'han carregat!",
      "Hurry up and fill in the contact data!": "Afanya't i emplena les dades de contacte!",
      // AngetReportView
      "Agent report": "Informe de l'agent",
      "ValueCalls": "{{value}} trucades",
      "Rate": "Valora",
      "Answered call": "Trucada contestada",
      "Unanswered call": "Trucada sense resposta",
      "Total call": "Trucada total",
      "calls": "trucades",
      "Total talk time": "Temps total de conversa",
      "Avg. talk time": "Temps mitjà de conversa",
      "Max talk time": "Temps màxim de conversa",
      "Answer Ratio": "Relació de respostes",
      "Answered / Unanswered": "Resposta / Sense resposta",
      "min": "min",
      // Soundboard
      "Soundboard": "Consola",
      "Audio": "Àudio",
      "Preview": "Vista prèvia",
      "Play to Contact": "Juga per contactar",
      "Audio played to the contact!": "Àudio reproduït al contacte!",
      "Error playing audio to contact!": "Error en reproduir àudio al contacte!",
      "Stop Audio": "Atura l'àudio",
      "Audio stopped!": "Àudio aturat!",
      // PushCRM
      "Push to CRM - Sent!": "Empènyer a CRM: s'ha enviat",
      "Error pushing to CRM URL!": "S'ha produït un error en enviar l'URL CRM.",
      "Push to CRM": "Empènyer a CRM",
    }
  },
};

const options = {
  // order and from where user language should be detected
  // order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
  order: ['navigator', 'localStorage'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/', sameSite: 'strict' }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: 'en',
    fallbackLng: 'en',
    debug: true,

    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    detection: options
  });

export default i18n;