import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import BaseRouter from './routes';
import 'antd/dist/antd.css';
import './App.css';
import CustomLayout from './containers/Layout'
import * as actions from './store/actions/auth';
import Login from './containers/Login';


class App extends React.Component {

  componentDidMount() {
    console.log('AgentUI Version:');
    console.log(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`)

    this.props.onTryAutoSignup();
    console.log('Configuration:');
    console.log(window._env_.REACT_APP_API_URL);
    console.log(window._env_.REACT_APP_LOGO_URL);
    console.log(window._env_.REACT_APP_LOGO_NEG_URL)
    // console.log(window._env_.WEBRTC_HOST);

    // Change Favicon
    const favicon = document.getElementById("favicon");
    favicon.href = window._env_.REACT_APP_FAVICON_URL;
  }

  render() {
    return (
      <div className="App">
        {
          this.props.isAuthenticated
            ?
            <Router>
              <CustomLayout {...this.props}>
                <BaseRouter />
              </CustomLayout>
            </Router>
            :
            <Login />
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
