import React from 'react';
import { connect } from 'react-redux';
import { Switch } from 'antd';
import * as actions from '../store/actions/contact';
import i18next from 'i18next';


class DNCButton extends React.Component {
  state = {
    dnc_value: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.subscriber_id !== this.props.subscriber_id) {
      this.setState({
        dnc_value: false,
      });
    }
  }

  onChangeDNC = addtodnc => {
    this.setState({
      dnc_value: addtodnc,
    });

    this.props.updateDNC(this.props.contact_data.subscriber_id, addtodnc);
  };

  render() {
    return (
      <Switch checkedChildren={i18next.t("Added To DNC")} unCheckedChildren={i18next.t("Add To DNC")} onChange={this.onChangeDNC} checked={this.state.dnc_value} />
    );
  }
}


const mapStateToProps = state => {
  return {
    contact_data: state.contact.contact_data
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateDNC: (subscriber_id, add_dnc) => dispatch(actions.updateDNC(subscriber_id, add_dnc)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DNCButton);
