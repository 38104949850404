import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Layout, Menu, Row, Col } from 'antd';
import { HomeOutlined, UnorderedListOutlined, LogoutOutlined, PhoneOutlined, BarChartOutlined, SoundOutlined } from '@ant-design/icons';
import AgentStatus from '../components/AgentStatus';
import * as actions from '../store/actions/auth';
import * as contact_actions from '../store/actions/contact';
// import DynamicSipProvider from '../components/DynamicSipProvider';
// import CallHandler from '../components/CallHandler';

import XferButton from '../components/XferButton';
import SingleHangupButton from '../components/SingleHangupButton';
import WrapupCountDown from '../components/WrapupCountDown';
import { Translation } from 'react-i18next';

import WebPhone from './WebPhone';


// try this
const { Header, Sider, Content, Footer } = Layout;

function Capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}


class CustomLayout extends Component {
  // const[dialstring, setDialstring] = useState('')

  constructor(props) {
    super(props);
    this.state = {
      dialstring: '',
      webrtc_enabled: false,
    };
  }

  componentDidMount() {
    // check if we should enalbe WebRTC
    const webrtc_enabled = localStorage.getItem('enable_webrtc') === 'true' ? true : false;
    this.setState({
      webrtc_enabled: webrtc_enabled,
    });
  }

  setDialstring = (dialstring) => {
    this.setState({
      dialstring: dialstring,
    });
  };

  onClickLogout = () => {
    this.props.logout();
    // this.logout()
  }

  render() {
    // const logo_url = 'http://127.0.0.1:8000/static/autodialer/images/nd-branding/logo-icon.png';
    const logo_url = window._env_.REACT_APP_LOGO_NEG_URL;
    const version = Capitalize(process.env.REACT_APP_NAME) + ` v${process.env.REACT_APP_VERSION}`;
    // const MySipProvider = DynamicSipProvider(CallHandler);
    // console.log("MySipProvider");
    // console.log(MySipProvider.props);
    // MySipProvider.updateSipConfig(mysipConfig);
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider trigger={null}>
          <div />
          {
            <div>
              <img src={logo_url} alt="Logo" className="logo" />
              <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                <Menu.Item key="1">
                  <Link to="/">
                    <HomeOutlined />
                    <Translation>{t => <span>{t('Home')}</span>}</Translation>
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to="/callbacklater/">
                    <PhoneOutlined />
                    <Translation>{t => <span>{t('Callback Later')}</span>}</Translation>
                  </Link>
                </Menu.Item>
                <Menu.Item key="3">
                  <Link to="/lastcalls/">
                    <UnorderedListOutlined />
                    <Translation>{t => <span>{t('Last calls')}</span>}</Translation>
                  </Link>
                </Menu.Item>
                <Menu.Item key="4">
                  <Link to="/agent_report/">
                    <BarChartOutlined />
                    <Translation>{t => <span>{t('Agent report')}</span>}</Translation>
                  </Link>
                </Menu.Item>
                <Menu.Item key="5">
                  <Link to="/soundboard/">
                    <SoundOutlined />
                    <Translation>{t => <span>{t('Soundboard')}</span>}</Translation>
                  </Link>
                </Menu.Item>
                <Menu.Item key="6" onClick={this.onClickLogout}>
                  <Link to="/logout/">
                    <LogoutOutlined />
                    <Translation>{t => <span>{t('Logout')}</span>}</Translation>
                  </Link>
                </Menu.Item>
              </Menu>


              <WrapupCountDown />

              <XferButton />

              {
                this.state.webrtc_enabled ?
                <WebPhone />
                  :
                  <p style={{ margin: 20 }}>
                    <SingleHangupButton />
                  </p>
              }
            </div>
          }

        </Sider>
        <Layout>
          <Header className="myheader">
            <Row type="flex" justify="space-between" align="top">
              {/* total 24 */}
              <Col span={24}>
                <AgentStatus username={this.props.username} />
              </Col>
            </Row>
          </Header>
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              background: '#fff',
              minHeight: 280,
            }}
          >
            {this.props.children}
          </Content>
          <Footer style={{ textAlign: 'center' }}> {version} </Footer>
        </Layout>
      </Layout >
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    username: state.auth.username,
  }
}


const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(actions.logout());
      dispatch(contact_actions.resetContact());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomLayout);
