import * as actionTypes from '../actions/actionType';
import { updateObject } from '../utility';

const initialState = {
  token: null,
  username: null,
  phone_login: null,
  phone_password: null,
  enable_webrtc: false,
  enable_webrtc_autoanswer: false,
  error: null,
  loading: false
}

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
}
const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    username: action.username,
    phone_login: action.phone_login,
    phone_password: action.phone_password,
    enable_webrtc: action.enable_webrtc,
    enable_webrtc_autoanswer: action.enable_webrtc_autoanswer,
    error: null,
    loading: false
  });
}

const authFail = (state, action) => {
  // console.log('action.error:');
  // console.log(action);
  return updateObject(state, {
    // error: action.error,
    error: "Authentication Failed!",
    loading: false
  });
}

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null
  });
}

// -------------------------
// REDUCER
// -------------------------
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START: return authStart(state, action);
    case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
    case actionTypes.AUTH_FAIL: return authFail(state, action);
    case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
    default:
      return state;
  }
};

export default authReducer;